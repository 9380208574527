import t from './tokens'

const theme = {
  theme: 'Light',
  colors: {
    background: t.color_background,
    brand: {
      darkness: t.color_brand_darkest,
      dark: t.color_brand_dark,
      pure: t.color_brand_pure,
      light: t.color_brand_light,
      lightest: t.color_brand_lightest
    },
    highlight: {
      darkest: t.color_highlight_darkest,
      dark: t.color_highlight_dark,
      pure: t.color_highlight_pure,
      light: t.color_highlight_light,
      lightest: t.color_highlight_lightest
    },
    global: {
      positive: {
        dark: t.global_positive_dark,
        pure: t.global_positive_pure,
        light: t.global_positive_light
      },
      negative: {
        dark: t.global_negative_dark,
        pure: t.global_negative_pure,
        light: t.global_negative_light,
        lightest: t.global_negative_lightest
      },
      informative: {
        dark: t.global_informative_dark,
        pure: t.global_informative_pure,
        light: t.global_informative_light
      },
      neutral: {
        black: t.global_neutral_black,
        darkest: t.global_neutral_darkest,
        dark: t.global_neutral_dark,
        medium: t.global_neutral_medium,
        light: t.global_neutral_light,
        lightest: t.global_neutral_lightest,
        white: t.global_neutral_white
      }
    }
  },
  opacity: {
    level: {
      weakest: t.opacity_level_weakest,
      weak: t.opacity_level_weak,
      intense: t.opacity_level_intense,
      semiopaque: t.opacity_level_semiopaque
    }
  },
  spacing: {
    stack: {
      nano: t.spacing_stack_nano,
      micro: t.spacing_stack_micro,
      tiny: t.spacing_stack_tiny,
      small: t.spacing_stack_small,
      medium: t.spacing_stack_medium,
      large: t.spacing_stack_large,
      huge: t.spacing_stack_huge,
      giant: t.spacing_stack_giant
    },
    inset: {
      micro: t.spacing_inset_micro,
      tiny: t.spacing_inset_tiny,
      small: t.spacing_inset_small,
      medium: t.spacing_inset_medium
    },
    squish: {
      micro: t.spacing_squish_micro,
      tiny: t.spacing_squish_tiny,
      small: t.spacing_squish_small,
      medium: t.spacing_squish_medium
    }
  },
  border: {
    radius: {
      none: t.border_radius_none,
      nano: t.border_radius_nano,
      micro: t.border_radius_micro,
      tiny: t.border_radius_tiny,
      small: t.border_radius_small,
      medium: t.border_radius_medium,
      large: t.border_radius_large,
      circle: t.border_radius_circle
    },
    width: {
      none: t.border_width_none,
      hairline: t.border_width_hairline,
      thin: t.border_width_thin,
      thick: t.border_width_thick,
      heavy: t.border_width_heavy
    }
  },
  font: {
    family: {
      headings: t.font_family_headings,
      base: t.font_family_base
    },
    weight: {
      black: t.font_weights_black,
      extraBold: t.font_weights_extra_bold,
      bold: t.font_weights_bold,
      regular: t.font_weights_regular,
      light: t.font_weights_light
    },
    size: {
      nano: t.font_size_nano,
      micro: t.font_size_micro,
      tiny: t.font_size_tiny,
      small: t.font_size_small,
      medium: t.font_size_medium,
      large: t.font_size_large,
      huge: t.font_size_huge,
      giant: t.font_size_giant,
      giantHuge: t.font_size_giant_huge
    }
  },
  line: {
    height: {
      default: t.line_height_default,
      tiny: t.line_height_tiny,
      small: t.line_height_small,
      medium: t.line_height_medium,
      large: t.line_height_large,
      huge: t.line_height_huge
    }
  },
  letter: {
    spacing: {
      tight: t.letter_spacing_tight,
      default: t.letter_spacing_default,
      tiny: t.letter_spacing_tiny
    }
  },
  shadow: {
    level: {
      one: t.shadow_level_1,
      two: t.shadow_level_2,
      three: t.shadow_level_3,
      four: t.shadow_level_4
    }
  }
}

export default theme
