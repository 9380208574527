import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const Title = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: ${props => props.theme.border.radius.micro};
  height: 40px;
  border: 0;
  outline: 0;
  padding: 0 10px;
  border-radius: ${props => props.theme.border.radius.micro};

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  svg {
    margin: 10px;
  }
`

export const Username = styled.span`
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.micro};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.colors.global.neutral.white};
  padding: 5px;
`

export const Options = styled.ul`
  list-style: none;
  position: absolute;
  top: 80%;
  left: 0;
  width: 100%;

  margin-top: 10px;

  background: ${props => props.theme.colors.global.neutral.white};
  border-radius: ${props => props.theme.border.radius.tiny};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 10px;
  padding: ${props => props.theme.spacing.stack.nano};
  border-color: ${props => props.theme.colors.global.neutral.lightest};
  border-style: solid;
  border-width: 2px;

  display: none;
`

export const Item = styled.li`
  height: 39px;
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.spacing.stack.micro};
  padding-bottom: ${props => props.theme.spacing.stack.micro};

  padding-left: ${props => props.theme.spacing.stack.tiny};
  padding-right: ${props => props.theme.spacing.stack.tiny};

  & + li {
    border-top-color: ${props => props.theme.colors.global.neutral.lightest};
    border-top-style: solid;
    border-top-width: 1px;
  }

  button {
    width: 100%;
    cursor: pointer;
    text-align: left;
    font-family: ${props => props.theme.font.family.base};
    font-size: ${props => props.theme.font.size.tiny};
    font-weight: ${props => props.theme.font.weight.bold};
    line-height: 16px;

    color: ${props => props.theme.colors.global.neutral.dark};
    background: none;
    border: none;
    outline: none;
  }
`

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.tiny};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: 16px;

  color: ${props => props.theme.colors.global.neutral.dark};
`

export const Container = styled.div`
  position: relative;
  min-width: 150px;
  width: 100%;
  margin: 0 10px;
  height: 80px;

  display: flex;
  align-items: center;

  &:hover {
    ${Options} {
      display: initial;
    }
  }
`
