import { Container } from './HelperText.styles'

interface Props {
  text: string | undefined
  disabled: boolean | undefined
  error: boolean | undefined
}

export function HelperText({ text, disabled = false, error = false }: Props) {
  return (
    <>
      {text && (
        <Container disabled={disabled} error={error} data-testid="helper-text">
          {text}
        </Container>
      )}
    </>
  )
}
