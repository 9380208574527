import { useTransition } from 'react-spring'

import { ToastMessage } from 'hooks/useToast'

import { Message } from './Message'

import { Container } from './Toast.styles'

interface Props {
  messages: ToastMessage[]
}

export function Toast({ messages }: Props) {
  const messageWithTransition = useTransition(
    messages,
    (message: ToastMessage) => message.id,
    {
      from: { right: '-120%', opacity: '0' },
      enter: { right: '0%', opacity: '1' },
      leave: { right: '-120%', opacity: '0' }
    } as any
  )

  return (
    <Container haveMessages={messages.length > 0}>
      {messageWithTransition.map(({ item, key, props }: any) => (
        <Message key={key} message={item} style={props} />
      ))}
    </Container>
  )
}
