import gql from 'graphql-tag'

export const CREATE_AUTHENTICATE = gql`
  mutation authenticate($username: String!, $password: String!, $code: String) {
    authenticate(
      input: { username: $username, password: $password, code: $code }
    ) {
      accessToken
      expiresIn
      expiresInTimestamps
      refreshToken
      refreshExpiresIn
      refreshExpiresInTimestamps
    }
  }
`
