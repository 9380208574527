import { useRef, useState, useEffect, useCallback, ChangeEvent } from 'react'

import { Input as InputAntDesign } from 'antd'

import iconClear from 'assets/iconClose.svg'
import iconSearch from 'assets/iconSearch.svg'

import { Label } from 'components/Form/Label'
import { HelperText } from 'components/Form/HelperText'

import { InputProps } from './Input.interface'

import {
  Container,
  InputText,
  StyledInputFormikAd,
  StyledInputAd,
  Icon
} from './Input.styles'

export function Input({
  label,
  name,
  type = 'text',
  placeholder,
  defaultValue,
  helperText,
  messageError,
  icon,
  inputSearch,
  required,
  hasFormik = true,
  isFloating = false,
  onClearValue,
  onChange,
  id,
  ...rest
}: InputProps) {
  const inputRef = useRef<InputAntDesign>(null)

  const [hasValue, setHasValue] = useState<boolean>(!!defaultValue)

  useEffect(() => {
    if (inputRef.current && messageError) {
      inputRef.current.focus()
    }
  }, [messageError])

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      onChange && onChange(e)

      if (value.length > 0) {
        setHasValue(true)
        return
      }

      setHasValue(false)
    },
    [onChange]
  )

  function handleClearValue() {
    if (onClearValue) {
      onClearValue()
    }

    setHasValue(false)
  }

  return (
    <Container
      disabled={rest.disabled}
      error={messageError || ''}
      data-testid="input"
    >
      {label && !isFloating && (
        <Label
          htmlFor={name}
          disabled={rest.disabled || false}
          error={!!messageError}
          required={required || false}
        >
          {label}
        </Label>
      )}

      <InputText className={isFloating ? 'Floating' : ''}>
        {hasFormik ? (
          <StyledInputFormikAd
            name={name}
            type={type}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={rest.disabled}
            onChange={handleOnChange}
            ref={inputRef}
          />
        ) : (
          <StyledInputAd
            name={name}
            type={type}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={rest.disabled}
            value={rest.value}
            onChange={handleOnChange}
            onKeyDown={rest.onKeyDown}
            ref={inputRef}
          />
        )}

        {label && isFloating && (
          <Label
            htmlFor={name}
            disabled={rest.disabled || false}
            error={!!messageError}
            required={required || false}
          >
            {label}
          </Label>
        )}

        {icon && onClearValue && hasValue && hasFormik && (
          <Icon src={iconClear} type="clear" onClick={handleClearValue} />
        )}

        {icon && messageError && hasValue && inputSearch && (
          <Icon src={iconSearch} type="search" />
        )}
      </InputText>

      <HelperText
        text={messageError || helperText}
        disabled={rest.disabled}
        error={!!messageError}
      />
    </Container>
  )
}
