import styled, { css } from 'styled-components'
import { animated } from 'react-spring'

interface ToastProps {
  type: 'success' | 'error' | 'info'
  description: string
}

const typeToast = {
  success: css`
    background: ${props => props.theme.colors.global.positive.pure};
    color: ${props => props.theme.colors.global.positive.light};
  `,
  error: css`
    background: ${props => props.theme.colors.global.negative.pure};
    color: ${props => props.theme.colors.global.negative.light};
  `,
  info: css`
    background: ${props => props.theme.colors.global.informative.pure};
    color: ${props => props.theme.colors.global.informative.light};
  `
}

export const Container = styled(animated.div)<ToastProps>`
  width: 360px;

  position: relative;
  padding: 15px 30px 15px 15px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  & + div {
    margin-top: 8px;
  }

  ${props => typeToast[props.type]}

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${props =>
    props.description.length === 0 &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`
