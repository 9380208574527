import { v4 } from 'uuid'

import { Link } from 'react-router-dom'

import { Breadcrumb as CustomBreadcrumb } from 'antd'

import { IBreadcrumbProps } from './Breadcrumb.interface'

import { Container, Content, CustomSeparator, Title } from './Breadcrumb.styles'

const Item = CustomBreadcrumb.Item

export function Breadcrumb({ items, title }: IBreadcrumbProps) {
  return (
    <Container data-testid="breadcrumb">
      <Content>
        <CustomBreadcrumb separator="›">
          {items.map(item => (
            <Item key={v4()}>
              {item.route ? (
                <Link to={item.route}>{item.label}</Link>
              ) : (
                item.label
              )}
            </Item>
          ))}
        </CustomBreadcrumb>

        <CustomSeparator>›</CustomSeparator>
      </Content>

      <Title>{title}</Title>
    </Container>
  )
}
