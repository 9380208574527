import styled, { css } from 'styled-components'

import {
  Input as InputFormikAd,
  InputNumber as NumberFormikAd
} from 'formik-antd'

import { Input as InputAd, InputNumber as NumberAd } from 'antd'

import ReactInputMask from 'react-input-mask'

import NumberFormat from 'react-number-format'

interface Props {
  disabled?: boolean
  error: string
}

interface IconProps {
  type?: 'clear' | 'search'
}

const { TextArea } = InputFormikAd

export const InputText = styled.div`
  position: relative;

  &.Floating {
    label {
      position: absolute;
      pointer-events: none;
      left: 20px;
      top: 20px;
      padding: 0 5px;
      background-color: ${props => props.theme.colors.background};
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      font-weight: ${props => props.theme.font.weight.regular};
    }

    input {
      font-weight: ${props => props.theme.font.weight.regular};
      &:focus {
        outline: none;
        ~ label {
          top: -8px;
          font-size: 13px;
          background-color: ${props => props.theme.colors.background};
        }
      }
    }

    input:not(:placeholder-shown) ~ label {
      top: -8px;
      font-size: 13px;
    }
  }
`

export const StyledInputFormikAd = styled(InputFormikAd)`
  width: 100%;
  height: 40px;
  border-style: solid;
  padding: 12px;
  text-align: left;
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.micro};
  font-family: ${props => props.theme.font.family.base};
  line-height: ${props => props.theme.line.height.large};
  color: ${props => props.theme.colors.global.neutral.medium};
  border-color: ${props => props.theme.colors.global.neutral.light};
  background-color: ${props => props.theme.colors.global.neutral.white};
  border-width: ${props => props.theme.border.width.hairline};
  border-radius: ${props => props.theme.border.radius.micro};
  outline: 0;
  margin: 4px 0px;
  transition: all 0.2s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.global.neutral.medium};
    color: ${props => props.theme.colors.global.neutral.black};
    box-shadow: unset;

    &::-webkit-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &:-ms-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &::placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }
  }
`

export const StyledInputAd = styled(InputAd)`
  width: 100%;
  height: 40px;
  border-style: solid;
  padding: 12px;
  text-align: left;
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.micro};
  font-family: ${props => props.theme.font.family.base};
  line-height: ${props => props.theme.line.height.large};
  color: ${props => props.theme.colors.global.neutral.medium};
  border-color: ${props => props.theme.colors.global.neutral.light};
  background-color: ${props => props.theme.colors.global.neutral.white};
  border-width: ${props => props.theme.border.width.hairline};
  border-radius: ${props => props.theme.border.radius.micro};
  outline: 0;
  margin: 4px 0px;
  transition: all 0.2s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.global.neutral.medium};
    color: ${props => props.theme.colors.global.neutral.black};
    box-shadow: unset;

    &::-webkit-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &:-ms-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &::placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }
  }
`

export const StyledInputNumberFormikAd = styled(NumberFormikAd)`
  &.ant-input-number {
    width: 100%;
    height: 40px;
    border-style: solid;
    text-align: left;
    border-color: ${props => props.theme.colors.global.neutral.light};
    background-color: ${props => props.theme.colors.global.neutral.white};
    border-width: ${props => props.theme.border.width.hairline};
    border-radius: ${props => props.theme.border.radius.micro};
    outline: 0;
    margin: 4px 0px;
    transition: all 0.2s;
    overflow: hidden;
    box-shadow: unset;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    .ant-input-number-input {
      font-weight: ${props => props.theme.font.weight.bold};
      font-size: ${props => props.theme.font.size.micro};
      font-family: ${props => props.theme.font.family.base};
      line-height: ${props => props.theme.line.height.large};
      color: ${props => props.theme.colors.global.neutral.medium};
      height: 38px;
      padding: 12px;
    }

    &:hover,
    &:focus {
      border-color: ${props => props.theme.colors.global.neutral.medium};
      color: ${props => props.theme.colors.global.neutral.black};
      box-shadow: unset;

      &::-webkit-input-placeholder {
        color: ${props => props.theme.colors.global.neutral.black};
      }

      &:-ms-input-placeholder {
        color: ${props => props.theme.colors.global.neutral.black};
      }

      &::placeholder {
        color: ${props => props.theme.colors.global.neutral.black};
      }
    }
  }
`

export const StyledInputNumberAd = styled(NumberAd)`
  &.ant-input-number {
    width: 100%;
    height: 40px;
    border-style: solid;
    text-align: left;
    border-color: ${props => props.theme.colors.global.neutral.light};
    background-color: ${props => props.theme.colors.global.neutral.white};
    border-width: ${props => props.theme.border.width.hairline};
    border-radius: ${props => props.theme.border.radius.micro};
    outline: 0;
    margin: 4px 0px;
    transition: all 0.2s;
    overflow: hidden;
    box-shadow: unset;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    .ant-input-number-input {
      font-weight: ${props => props.theme.font.weight.bold};
      font-size: ${props => props.theme.font.size.micro};
      font-family: ${props => props.theme.font.family.base};
      line-height: ${props => props.theme.line.height.large};
      color: ${props => props.theme.colors.global.neutral.medium};
      height: 38px;
      padding: 12px;
    }

    &:hover,
    &:focus {
      border-color: ${props => props.theme.colors.global.neutral.medium};
      color: ${props => props.theme.colors.global.neutral.black};
      box-shadow: unset;

      &::-webkit-input-placeholder {
        color: ${props => props.theme.colors.global.neutral.black};
      }

      &:-ms-input-placeholder {
        color: ${props => props.theme.colors.global.neutral.black};
      }

      &::placeholder {
        color: ${props => props.theme.colors.global.neutral.black};
      }
    }
  }
`

export const StyledMask = styled(ReactInputMask)`
  width: 100%;
  height: 40px;
  border-style: solid;
  padding: 12px;
  text-align: left;
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.micro};
  font-family: ${props => props.theme.font.family.base};
  line-height: ${props => props.theme.line.height.large};
  color: ${props => props.theme.colors.global.neutral.medium};
  border-color: ${props => props.theme.colors.global.neutral.light};
  background-color: ${props => props.theme.colors.global.neutral.white};
  border-width: ${props => props.theme.border.width.hairline};
  border-radius: ${props => props.theme.border.radius.micro};
  outline: 0;
  margin: 4px 0px;
  transition: all 0.2s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.global.neutral.medium};
    color: ${props => props.theme.colors.global.neutral.black};

    &::-webkit-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &:-ms-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &::placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }
  }
`

export const StyledCurrency = styled(NumberFormat)`
  width: 100%;
  height: 40px;
  border-style: solid;
  padding: 12px;
  text-align: left;
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.micro};
  font-family: ${props => props.theme.font.family.base};
  line-height: ${props => props.theme.line.height.large};
  color: ${props => props.theme.colors.global.neutral.medium};
  border-color: ${props => props.theme.colors.global.neutral.light};
  background-color: ${props => props.theme.colors.global.neutral.white};
  border-width: ${props => props.theme.border.width.hairline};
  border-radius: ${props => props.theme.border.radius.micro};
  outline: 0;
  margin: 4px 0px;
  transition: all 0.2s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.global.neutral.medium};
    color: ${props => props.theme.colors.global.neutral.black};

    &::-webkit-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &:-ms-input-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &::placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }
  }
`

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  border-style: solid;
  padding: 12px;
  text-align: left;
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.micro};
  font-family: ${props => props.theme.font.family.base};
  line-height: ${props => props.theme.line.height.large};
  color: ${props => props.theme.colors.global.neutral.medium};
  border-color: ${props => props.theme.colors.global.neutral.light};
  background-color: ${props => props.theme.colors.global.neutral.white};
  border-width: ${props => props.theme.border.width.hairline};
  border-radius: ${props => props.theme.border.radius.micro};
  outline: 0;
  margin: 4px 0px;
  transition: all 0.2s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.global.neutral.medium};
    box-shadow: unset;
    color: ${props => props.theme.colors.global.neutral.black};

    &::-webkit-textarea-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &:-ms-textarea-placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }

    &::placeholder {
      color: ${props => props.theme.colors.global.neutral.black};
    }
  }
`

export const Icon = styled.img<IconProps>`
  position: absolute;
  width: 24px;
  right: 15px;
  top: 12px;

  ${props =>
    props.type === 'clear' &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.type === 'search' &&
    css`
      width: 16px;
      top: 16px;
    `}
`

export const Container = styled.div<Props>`
  ${props =>
    props.disabled &&
    css`
      ${StyledInputFormikAd} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }

      ${StyledInputAd} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }

      ${StyledInputNumberFormikAd} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }

      ${StyledInputNumberAd} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }

      ${StyledMask} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }

      ${StyledCurrency} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }

      ${StyledTextArea} {
        opacity: ${props.theme.opacity.level.semiopaque};
        color: rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
        cursor: not-allowed;

        &:hover {
          color: ${props.theme.colors.global.neutral.medium};
          border-color: ${props.theme.colors.global.neutral.light};

          &::-webkit-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &:-ms-input-placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }

          &::placeholder {
            color: ${props.theme.colors.global.neutral.medium};
          }
        }
      }
    `}

  ${props =>
    props.error !== '' &&
    css`
      ${StyledInputFormikAd} {
        border-color: #df0688;
      }

      ${StyledInputAd} {
        border-color: #df0688;
      }

      ${StyledInputNumberFormikAd} {
        border-color: #df0688;
      }

      ${StyledInputNumberAd} {
        border-color: #df0688;
      }

      ${StyledMask} {
        border-color: #df0688;
      }

      ${StyledCurrency} {
        border-color: #df0688;
      }

      ${StyledTextArea} {
        border-color: #df0688;
      }
    `}
`
