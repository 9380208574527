import { ReactSVG } from 'react-svg'

import { useAuth } from 'hooks/useAuth'
import useMock from 'hooks/useMock'

import iconDown from 'assets/iconArrowDownWhite.svg'
import { ReactComponent as IconSignout } from 'assets/iconSignout.svg'

import {
  Container,
  Title,
  Username,
  Options,
  Item,
  StyledLink
} from './SelectOptions.styles'

export function SelectOptions() {
  const { headerSelect } = useMock()

  const { signOut, user } = useAuth()
  return (
    <Container>
      <Title>
        <Username>{user?.username}</Username>

        <ReactSVG src={iconDown} />
      </Title>

      <Options>
        {headerSelect.items.map((option, index) => (
          <Item key={index}>
            <StyledLink to={option.link}>{option.title}</StyledLink>
          </Item>
        ))}

        <Item className="negative-pure">
          <button type="button" onClick={() => signOut()}>
            <span>Sair</span>
          </button>
          <IconSignout />
        </Item>
      </Options>
    </Container>
  )
}
