import {
  createContext,
  useContext,
  useCallback,
  useState,
  ReactNode
} from 'react'

import { Loading } from 'components/Loading'

type LoaderContextData = {
  visible: boolean
  showLoader: () => void
  hideLoader: () => void
}

type Props = {
  children?: ReactNode
}

export const LoaderContext = createContext<LoaderContextData>(
  {} as LoaderContextData
)

export function LoaderProvider({ children }: Props) {
  const [visible, setVisible] = useState<boolean>(false)

  const showLoader = useCallback(() => {
    setVisible(true)
  }, [])

  const hideLoader = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <LoaderContext.Provider value={{ visible, showLoader, hideLoader }}>
      {children}

      {visible && <Loading />}
    </LoaderContext.Provider>
  )
}

export function useLoader(): LoaderContextData {
  const context = useContext(LoaderContext)

  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider.')
  }

  return context
}
