import styled, { css } from 'styled-components'

interface ItemProps {
  disabled?: boolean
  isActive?: boolean
}

export const Container = styled.div`
  margin-top: 80px;
  height: calc(100vh - 80px);
  width: 104px;
  background: #f4f4f6;
  border-right: 1px solid #cfd0d8;
  position: relative;
  box-shadow: 4px 0px 80px #d3d4dc;
  z-index: 99;
`

export const Menu = styled.ul`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
`

export const Submenu = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  width: 320px;
  height: calc(100vh - 80px);
  background: #f4f4f6;
  border-left: 1px solid #cfd0d8;
  padding-top: ${props => props.theme.spacing.stack.micro};
  padding-bottom: ${props => props.theme.spacing.stack.micro};
  padding-left: ${props => props.theme.spacing.stack.tiny};
  padding-right: ${props => props.theme.spacing.stack.tiny};
  display: none;

  .title {
    margin-top: 24px;
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid ${props => props.theme.colors.global.neutral.light};
    margin-right: 20px;
    padding-bottom: 20px;

    h1 {
      color: ${props => props.theme.colors.highlight.pure};
      font-size: 24px;
      font-weight: 700;
      margin-left: 10px;
    }

    .icon-svg-submenu {
      width: 25px;
      height: 25px;

      path {
        fill: ${props => props.theme.colors.highlight.pure};
        stroke: ${props => props.theme.colors.highlight.pure};
      }
    }
  }

  ul {
    list-style: none;
  }
`

export const ItemSubmenu = styled.li<ItemProps>`
  padding: 0 10px;
  margin: 10px 0px;

  a {
    text-align: left;
    text-decoration: none;
    color: ${props => props.theme.colors.global.neutral.medium};
    font-weight: 700;
    width: 100%;

    &:hover {
      color: ${props => props.theme.colors.global.neutral.lightest};
      background: ${props => props.theme.colors.highlight.pure};
    }

    ${props =>
      props.disabled &&
      css`
        &:hover {
          background: ${props => props.theme.colors.global.neutral.medium};
          cursor: not-allowed;
        }
      `}

    ${props =>
      props.isActive &&
      css`
        & {
          background: ${props => props.theme.colors.highlight.pure};
          color: ${props => props.theme.colors.global.neutral.lightest};
        }
      `}
  }
`

export const ItemMenu = styled.li<ItemProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: ${props => props.theme.spacing.stack.micro};
  padding-bottom: ${props => props.theme.spacing.stack.micro};
  padding-left: ${props => props.theme.spacing.stack.tiny};
  padding-right: ${props => props.theme.spacing.stack.tiny};

  & > a {
    height: 56px;
    width: 56px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  a {
    display: inline-block;
    padding: ${props => props.theme.spacing.stack.tiny};
    border-radius: ${props => props.theme.border.radius.micro};
    transition: 0.3s;

    .icon-svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${props => props.theme.colors.global.neutral.dark};
      }

      ${props =>
        props.disabled &&
        css`
          opacity: 0.3;
        `}
    }
  }

  &.menu-item-active > a {
    background: ${props => props.theme.colors.highlight.pure};

    .icon-svg {
      path {
        fill: ${props => props.theme.colors.global.neutral.lightest};
      }
    }
  }

  &:hover > a {
    background: ${props => props.theme.colors.highlight.pure};
    cursor: pointer;

    .icon-svg {
      path {
        fill: ${props => props.theme.colors.global.neutral.lightest};
      }
    }

    ${props =>
      props.disabled &&
      css`
          cursor: not-allowed;
          background:0;

          .icon-svg{
            path{
              fill:${props => props.theme.colors.global.neutral.light};
            }

        `}
  }

  &:hover .submenu {
    display: block;
  }
`
