const path = '/seller'

export const ROUTE_SELLER_LIST = `${path}/list`
export const ROUTE_CREATE_SELLER = `${path}/create`
export const ROUTE_EDIT_SELLER = `${path}/edit`
export const ROUTE_SHOW_SELLER = `${path}`
export const ROUTE_SELLER_BRANCH_LIST = `${path}/branch`
export const ROUTE_SELLER_BRANCH_CREATE = `${path}/branch/create`
export const ROUTE_SELLER_BRANCH_EDIT = `${path}/branch/edit`

export const ROUTE_CREATE_REGISTER_REQUEST = `${path}/register-request`
export const ROUTE_MULTISELLER_LIST = `${path}/multiseller/list`
export const ROUTE_MULTISELLER_CREATE = `${path}/multiseller/create`
export const ROUTE_MULTISELLER_EDIT = `${path}/multiseller/edit`
export const ROUTE_SHOW_MULTISELLER = `${path}/multiseller`

export const ROUTE_DATA_SELLER = `${path}/my-info`
