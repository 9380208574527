import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

const CreateRepresentative = lazy(() =>
  import('pages/Settings/Representative').then(module => ({
    default: module.CreateRepresentative
  }))
)

const ListUser = lazy(() =>
  import('pages/Settings/User/List').then(module => ({
    default: module.ListUser
  }))
)

const CreateUser = lazy(() =>
  import('pages/Settings/User/Create').then(module => ({
    default: module.CreateUser
  }))
)

const ListIntegrator = lazy(() =>
  import('pages/Settings/Integrator/List').then(module => ({
    default: module.ListIntegrator
  }))
)

const path = '/settings'

export const ROUTE_CREATE_REPRESENTATIVE = `${path}/create-representative`
export const ROUTE_LIST_USERS = `${path}/list-user`
export const ROUTE_CREATE_USER = `${path}/create-user`
export const ROUTE_EDIT_USER = `${path}/edit-user`
export const ROUTE_LIST_INTEGRATOR = `${path}/list-integrator`

export const settingsRoutes: IRouteProps[] = [
  {
    path: ROUTE_CREATE_REPRESENTATIVE,
    exact: true,
    name: 'Create Representative',
    component: CreateRepresentative,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Configurações'
        }
      ],
      title: 'Criar Responsável'
    }
  },
  {
    path: ROUTE_LIST_USERS,
    exact: true,
    name: 'List User',
    component: ListUser,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.suporte],
    breadcrumb: {
      items: [
        {
          label: 'Configurações'
        }
      ],
      title: 'Listar Usuários'
    }
  },
  {
    path: ROUTE_CREATE_USER,
    exact: true,
    name: 'Create User',
    component: CreateUser,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.suporte],
    breadcrumb: {
      items: [
        {
          label: 'Configurações',
          route: ROUTE_LIST_USERS
        }
      ],
      title: 'Criar Usuário'
    }
  },
  {
    path: `${ROUTE_EDIT_USER}/:id`,
    exact: true,
    name: 'Edit User',
    component: CreateUser,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.suporte],
    breadcrumb: {
      items: [
        {
          label: 'Configurações',
          route: ROUTE_LIST_USERS
        }
      ],
      title: 'Editar Usuário'
    }
  },
  {
    path: ROUTE_LIST_INTEGRATOR,
    exact: true,
    name: 'List Integrator',
    component: ListIntegrator,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.suporte],
    breadcrumb: {
      items: [
        {
          label: 'Configurações'
        }
      ],
      title: 'Listar Integradoras'
    }
  }
]
