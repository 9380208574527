import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'

interface ContentProps {
  custom: 'primary' | 'secondary' | 'icon' | 'ghost' | 'danger'
  iconAlign?: 'left' | 'right'
}

export const ContentLink = styled(Link)`
  color: ${props => props.theme.colors.global.neutral.medium};
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.tiny};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.line.height.default};
  letter-spacing: ${props => props.theme.letter.spacing.default};
  text-align: left;
  text-decoration: unset;
  transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
  transition-duration: 0.6s;
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${props => props.theme.colors.global.neutral.medium};
    }
  }

  img {
    float: right;
    padding-left: 5px;
  }

  &:hover {
    color: ${props => props.theme.colors.highlight.light};
    text-decoration: underline;

    svg {
      path {
        fill: ${props => props.theme.colors.highlight.light};
      }
    }
  }

  &:active {
    color: ${props => props.theme.colors.highlight.dark};
  }

  &:focus {
    color: ${props => props.theme.colors.highlight.dark};
    border-width: ${props => props.theme.border.width.thin};
    border-style: solid;
    border-color: ${props => props.theme.colors.highlight.pure};
  }

  &.disabled {
    color: ${props => props.theme.colors.global.neutral.light};
    border-style: none;
  }
`

export const Content = styled.button<ContentProps>`
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.nano};
  border-radius: ${props => props.theme.border.radius.tiny};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.line.height.tiny};
  text-align: center;
  padding: 0px 16px;
  height: 40px;
  text-decoration: unset;
  transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
  transition-duration: 0.3s;
  width: max-content;
  cursor: pointer;
  box-shadow: none;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.iconAlign === 'right' &&
    css`
      svg {
        margin-left: 10px;
      }
    `}

  ${props =>
    props.iconAlign === 'left' &&
    css`
      svg {
        margin-right: 10px;
      }
    `}

  ${props =>
    props.custom === 'primary' &&
    css`
      color: ${props.theme.colors.global.neutral.white};
      background-color: ${props.theme.colors.highlight.pure};
      border: 0;

      svg {
        path {
          fill: ${props.theme.colors.global.neutral.white};
        }
      }

      &:hover {
        background-color: ${props.theme.colors.highlight.light};
        box-shadow: none;
      }

      &:active {
        background-color: ${props.theme.colors.highlight.dark};
        transform: scale(0.97);
      }

      &:focus {
        background-color: ${props.theme.colors.highlight.light};
        border-width: ${props.theme.border.width.thin};
        border-style: solid;
        border-color: ${props.theme.colors.highlight.pure};
        box-shadow: none;
      }

      &:disabled {
        background-color: ${props.theme.colors.global.neutral.light};
        color: ${props.theme.colors.global.neutral.darkest};
        opacity: ${props.theme.opacity.level.weak};
      }
    `}

  ${props =>
    props.custom === 'secondary' &&
    css`
      color: ${props.theme.colors.global.neutral.medium};
      background-color: transparent;
      border-width: ${props.theme.border.width.hairline};
      border-color: ${props.theme.colors.global.neutral.light};
      border-style: solid;

      svg {
        path {
          fill: ${props.theme.colors.global.neutral.medium};
        }
      }

      &:hover {
        color: ${props.theme.colors.global.neutral.darkest};
        background-color: rgba(207, 208, 216, 0.32);
        box-shadow: none;
        border-color: transparent;
      }

      &:active {
        color: ${props.theme.colors.global.neutral.darkest};
        background-color: ${props.theme.colors.global.neutral.light};
        transform: scale(0.97);
        border-color: transparent;
      }

      &:focus {
        color: ${props.theme.colors.global.neutral.darkest};
        background-color: ${props.theme.colors.global.neutral.light};
        border-width: ${props.theme.border.width.thin};
        border-style: solid;
        border-color: ${props.theme.colors.global.neutral.medium};
        box-shadow: none;
      }

      &:disabled {
        background-color: ${props.theme.colors.global.neutral.light};
        color: ${props.theme.colors.global.neutral.darkest};
        opacity: ${props.theme.opacity.level.weak};
      }
    `}

    ${props =>
    props.custom === 'danger' &&
    css`
      color: ${props.theme.colors.global.negative.light};
      background-color: ${props.theme.colors.global.negative.pure};
      border: 0;

      svg {
        path {
          fill: ${props.theme.colors.global.neutral.white};
        }
      }

      &:hover {
        background-color: ${props.theme.colors.global.negative.lightest};
        box-shadow: none;
      }

      &:active {
        background-color: ${props.theme.colors.global.negative.dark};
        transform: scale(0.97);
      }

      &:focus {
        background-color: ${props.theme.colors.global.negative.lightest};
        border-width: ${props.theme.border.width.thin};
        border-style: solid;
        border-color: ${props.theme.colors.global.negative.pure};
        box-shadow: none;
      }

      &:disabled {
        background-color: ${props.theme.colors.global.neutral.light};
        color: ${props.theme.colors.global.neutral.darkest};
        opacity: ${props.theme.opacity.level.weak};
      }
    `}

  ${props =>
    props.custom === 'icon' &&
    css`
      color: ${props.theme.colors.highlight.pure};
      background-color: transparent;
      padding: ${props.theme.spacing.inset.micro};
      border: 0;
      width: 56px;
      height: 56px;

      svg {
        path {
          fill: ${props.theme.colors.highlight.pure};
        }
      }

      img {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: ${props.theme.colors.brand.lightest};
      }

      &:active {
        color: ${props.theme.colors.highlight.darkest};
        background-color: ${props.theme.colors.highlight.light};
        transform: scale(0.97);
      }

      &:focus {
        color: ${props.theme.colors.highlight.pure};
        background-color: ${props.theme.colors.brand.lightest};
        border-width: ${props.theme.border.width.thin};
        border-style: solid;
        border-color: ${props.theme.colors.highlight.pure};
        box-shadow: ${props.theme.shadow.level.one};
      }

      &:disabled {
        color: ${props.theme.colors.global.neutral.darkest};
        opacity: ${props.theme.opacity.level.weak};
      }
    `}

  ${props =>
    props.custom === 'ghost' &&
    css`
      background-color: transparent;
      border: unset;
      color: ${props.theme.colors.global.neutral.medium};

      svg {
        path {
          fill: ${props.theme.colors.global.neutral.medium};
        }
      }

      &:hover {
        background-color: rgba(207, 208, 216, 0.32);
        color: ${props.theme.colors.global.neutral.darkest};
      }

      &:active {
        color: ${props.theme.colors.global.neutral.darkest};
        background-color: ${props.theme.colors.global.neutral.light};
        transform: scale(0.97);
      }

      &:focus {
        color: ${props.theme.colors.global.neutral.darkest};
        background-color: ${props.theme.colors.global.neutral.light};
        border-width: ${props.theme.border.width.thin};
        border-style: solid;
        border-color: ${props.theme.colors.global.neutral.medium};
        box-shadow: none;
      }

      &:disabled {
        color: ${props.theme.colors.global.neutral.darkest};
        background-color: ${props.theme.colors.global.neutral.light};
        opacity: ${props.theme.opacity.level.weak};
      }
    `}
`
