import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

import {
  ROUTE_CREATE_TRANSFER,
  ROUTE_ACCOUNT_WITHDRAWAL,
  ROUTE_GET_BALANCE
} from './paths'

const CreateTransfer = lazy(() =>
  import('pages/TransferBetweenSchedules/CreateTransfer').then(module => ({
    default: module.CreateTransfer
  }))
)

const CreateWithdrawal = lazy(() =>
  import('pages/TransferBetweenSchedules/AccountWithdrawal').then(module => ({
    default: module.CreateWithdrawal
  }))
)

const GetBalance = lazy(() =>
  import('pages/TransferBetweenSchedules/GetBalance').then(module => ({
    default: module.GetBalance
  }))
)

export const transferRoutes: IRouteProps[] = [
  {
    path: ROUTE_CREATE_TRANSFER,
    exact: true,
    name: 'Create Transfer',
    component: CreateTransfer,
    isPrivate: true,
    roles: [ROLES.financial, ROLES.admin, ROLES.fiscal],
    breadcrumb: {
      items: [
        {
          label: 'Agendas'
        }
      ],
      title: 'Transferência entre contas'
    }
  },
  {
    path: ROUTE_ACCOUNT_WITHDRAWAL,
    exact: true,
    name: 'Create Withdrawal',
    component: CreateWithdrawal,
    isPrivate: true,
    roles: [ROLES.financial, ROLES.admin, ROLES.fiscal],
    breadcrumb: {
      items: [
        {
          label: 'Agendas'
        }
      ],
      title: 'Saque de contas'
    }
  },
  {
    path: ROUTE_GET_BALANCE,
    exact: true,
    name: 'Get Balance',
    component: GetBalance,
    isPrivate: true,
    roles: [ROLES.financial, ROLES.admin, ROLES.fiscal],
    breadcrumb: {
      items: [
        {
          label: 'Agendas'
        }
      ],
      title: 'Saldo de contas'
    }
  }
]
