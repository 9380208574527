import {
  Container,
  StyledLoading,
  FirstEllipsis,
  SecondEllipsis,
  ThirdEllipsis,
  FourthEllipsis
} from './Loading.styles'

export function Loading() {
  return (
    <Container data-testid="loading">
      <StyledLoading>
        <FirstEllipsis />

        <SecondEllipsis />

        <ThirdEllipsis />

        <FourthEllipsis />
      </StyledLoading>
    </Container>
  )
}
