import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

export const client = () => {
  const token = localStorage.getItem('@RDMarketplace:token')
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_GRAPHQL,
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      })
    }

    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: from([errorLink, httpLink])
  })
}
