import React, { createContext, useCallback, useContext, useState } from 'react'

import { Modal } from 'components/Modal'

interface IAddModal {
  content: JSX.Element
  header?: string
}

interface ModalContextData {
  addModal(data: IAddModal): void
  closeModal(): void
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData)

const ModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [element, setElement] = useState<IAddModal>({} as IAddModal)

  const addModal = useCallback(({ content, header }: IAddModal) => {
    setElement({ content, header })
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setElement({} as IAddModal)
  }, [])

  return (
    <ModalContext.Provider value={{ addModal, closeModal }}>
      {children}
      {isOpen && (
        <Modal
          closeModal={closeModal}
          element={element.content}
          openModal={isOpen}
          header={element.header}
        />
      )}
    </ModalContext.Provider>
  )
}

function useModal(): ModalContextData {
  const context = useContext(ModalContext)
  return context
}

export { useModal, ModalProvider }
