import { ReactNode } from 'react'

import { Container } from './Label.styles'

interface Props {
  htmlFor: string
  disabled: boolean | undefined
  error: boolean | undefined
  required: boolean | undefined
  children: ReactNode
}

export function Label({
  htmlFor,
  disabled = false,
  error = false,
  required = false,
  children
}: Props) {
  return (
    <Container
      htmlFor={htmlFor}
      disabled={disabled}
      error={error}
      required={required}
      data-testid="label"
    >
      {children}
    </Container>
  )
}
