import gql from 'graphql-tag'

export const CREATE_AUTHENTICATE_REFRESH = gql`
  mutation refreshToken($token: String!) {
    refreshAuth(input: $token) {
      accessToken
      expiresIn
      refreshExpiresIn
      refreshToken
      expiresInTimestamps
      refreshExpiresInTimestamps
    }
  }
`
