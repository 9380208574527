import { Heading } from 'components/Typography/Heading'

import { IModalProps } from './Modal.interface'
import { Backdrop, Container, Header, CloseButton, Body } from './Modal.styles'
import { FiX } from 'react-icons/fi'
export function Modal({ closeModal, element, openModal, header }: IModalProps) {
  return (
    <>
      {openModal && (
        <Backdrop data-testid="modal">
          <Container>
            <Header>
              {header && <Heading type="medium">{header}</Heading>}

              <CloseButton
                onClick={() => closeModal()}
                data-testid="close-modal"
              >
                <FiX color="#DF0688" size={24} />
              </CloseButton>
            </Header>

            <Body>{element}</Body>
          </Container>
        </Backdrop>
      )}
    </>
  )
}
