import styled, { css } from 'styled-components'

interface Props {
  type:
    | 'form'
    | 'formSmall'
    | 'tiny'
    | 'small'
    | 'medium'
    | 'large'
    | 'huge'
    | 'giant'
}

export const Typography = styled.div<Props>`
  color: ${props => props.theme.colors.global.neutral.black};
  font-family: ${props => props.theme.font.family.headings};
  font-weight: ${props => props.theme.font.weight.bold};
  font-style: normal;
  text-align: left;
  letter-spacing: ${props => props.theme.letter.spacing.default};

  ${props =>
    props.type === 'giant' &&
    css`
      font-size: ${props.theme.font.size.giant};
      line-height: ${props.theme.line.height.medium};
    `}

  ${props =>
    props.type === 'huge' &&
    css`
      font-size: ${props.theme.font.size.huge};
      line-height: ${props.theme.line.height.tiny};
    `}

  ${props =>
    props.type === 'large' &&
    css`
      font-size: ${props.theme.font.size.large};
      line-height: ${props.theme.line.height.small};
    `}

  ${props =>
    props.type === 'medium' &&
    css`
      font-size: ${props.theme.font.size.medium};
      line-height: ${props.theme.line.height.medium};
    `}

  ${props =>
    props.type === 'small' &&
    css`
      font-size: ${props.theme.font.size.small};
      line-height: ${props.theme.line.height.small};
    `}

  ${props =>
    props.type === 'small' &&
    css`
      font-size: ${props.theme.font.size.tiny};
      line-height: ${props.theme.line.height.tiny};
    `}

  ${props =>
    props.type === 'form' &&
    css`
      font-size: ${props.theme.font.size.small};
      line-height: ${props.theme.line.height.small};

      border-bottom: thin solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
    `}

    ${props =>
    props.type === 'formSmall' &&
    css`
      font-size: ${props.theme.font.size.tiny};
      line-height: ${props.theme.line.height.small};

      border-bottom: thin solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
    `}
`
