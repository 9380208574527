import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

import {
  ROUTE_CREATE_SELLER,
  ROUTE_SELLER_LIST,
  ROUTE_EDIT_SELLER,
  ROUTE_SHOW_SELLER,
  ROUTE_SELLER_BRANCH_CREATE,
  ROUTE_SELLER_BRANCH_LIST,
  ROUTE_SELLER_BRANCH_EDIT,
  ROUTE_MULTISELLER_LIST,
  ROUTE_MULTISELLER_CREATE,
  ROUTE_MULTISELLER_EDIT,
  ROUTE_SHOW_MULTISELLER,
  ROUTE_DATA_SELLER
} from './paths'

const SellerList = lazy(() =>
  import('pages/Seller/Private/SellerList').then(module => ({
    default: module.SellerList
  }))
)

const MultiSellerList = lazy(() =>
  import('pages/MultiSeller/List').then(module => ({
    default: module.MultiSellerList
  }))
)

const MultiSellerCreate = lazy(() =>
  import('pages/MultiSeller/Create').then(module => ({
    default: module.MultiSellerCreate
  }))
)

const SellerRegistration = lazy(() =>
  import('pages/Seller/Private/SellerRegistration').then(module => ({
    default: module.SellerRegistration
  }))
)

const CreateSellerBranch = lazy(() =>
  import('pages/Seller/Private/CreateBranch').then(module => ({
    default: module.CreateBranch
  }))
)

const ListSellerBranch = lazy(() =>
  import('pages/Seller/Private/ListBranch').then(module => ({
    default: module.ListBranch
  }))
)

const MyInfo = lazy(() =>
  import('pages/Seller/Private/RegistrationData').then(module => ({
    default: module.RegistrationData
  }))
)

export const sellerPrivateRoutes: IRouteProps[] = [
  {
    path: ROUTE_SELLER_LIST,
    exact: true,
    name: 'List Seller',
    component: SellerList,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal,
      ROLES.regulatorio
    ],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_LIST
        }
      ],
      title: 'Listagem de Sellers'
    }
  },
  {
    path: `${ROUTE_DATA_SELLER}`,
    exact: true,
    name: 'Show Data Seller',
    component: MyInfo,
    isPrivate: true,
    roles: [ROLES.seller],
    breadcrumb: {
      items: [],
      title: 'Minhas Informações'
    }
  },
  {
    path: ROUTE_MULTISELLER_LIST,
    exact: true,
    name: 'Gerenciar Multi Seller',
    component: MultiSellerList,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Multi Seller'
        }
      ],
      title: 'Gerenciar Multi Seller'
    }
  },
  {
    path: ROUTE_MULTISELLER_CREATE,
    exact: true,
    name: 'Create Multi Seller',
    component: MultiSellerCreate,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.setup, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Multi Seller',
          route: ROUTE_MULTISELLER_LIST
        }
      ],
      title: 'Criar Multi Seller'
    }
  },
  {
    path: `${ROUTE_MULTISELLER_EDIT}/:id`,
    exact: true,
    name: 'Edit Multi Seller',
    component: MultiSellerCreate,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.setup, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Multi Seller',
          route: ROUTE_MULTISELLER_LIST
        }
      ],
      title: 'Editar Multi Seller'
    }
  },
  {
    path: `${ROUTE_SHOW_MULTISELLER}/:id`,
    exact: true,
    name: 'Show MultiSeller',
    component: MultiSellerCreate,
    isPrivate: true,
    roles: [
      ROLES.backoffice,
      ROLES.farming,
      ROLES.hunting,
      ROLES.onboarding,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.fiscal,
      ROLES.qualidade,
      ROLES.admin
    ],
    breadcrumb: {
      items: [
        {
          label: 'Multi Seller',
          route: ROUTE_MULTISELLER_LIST
        }
      ],
      title: 'Exibir MultiSeller'
    }
  },
  {
    path: ROUTE_SELLER_BRANCH_LIST,
    exact: true,
    name: 'List Seller Branch',
    component: ListSellerBranch,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_LIST
        }
      ],
      title: 'Filiais'
    }
  },
  {
    path: ROUTE_SELLER_BRANCH_CREATE,
    exact: true,
    name: 'Create Seller Branch',
    component: CreateSellerBranch,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.setup, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_BRANCH_LIST
        }
      ],
      title: 'Criar Filial'
    }
  },
  {
    path: `${ROUTE_SELLER_BRANCH_EDIT}/:id`,
    exact: true,
    name: 'Edit Seller Branch',
    component: CreateSellerBranch,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.setup, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_BRANCH_LIST
        }
      ],
      title: 'Editar Filial'
    }
  },
  {
    path: ROUTE_CREATE_SELLER,
    exact: true,
    name: 'Create Seller',
    component: SellerRegistration,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.qualidade,
      ROLES.setup,
      ROLES.financial,
      ROLES.incubadora
    ],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_LIST
        }
      ],
      title: 'Criar Cadastro do Seller'
    }
  },
  {
    path: `${ROUTE_EDIT_SELLER}/:id`,
    exact: true,
    name: 'Edit Seller',
    component: SellerRegistration,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.setup,
      ROLES.financial,
      ROLES.qualidade,
      ROLES.incubadora
    ],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_LIST
        }
      ],
      title: 'Editar Cadastro do Seller'
    }
  },
  {
    path: `${ROUTE_SHOW_SELLER}/:id`,
    exact: true,
    name: 'Show Seller',
    component: SellerRegistration,
    isPrivate: true,
    roles: [
      ROLES.backoffice,
      ROLES.farming,
      ROLES.hunting,
      ROLES.onboarding,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.fiscal,
      ROLES.regulatorio
    ],
    breadcrumb: {
      items: [
        {
          label: 'Seller',
          route: ROUTE_SELLER_LIST
        }
      ],
      title: 'Exibir Seller'
    }
  }
]
