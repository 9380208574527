import { Suspense } from 'react'

import { useAuth } from 'hooks/useAuth'

import { Loading } from 'components/Loading'

import { Menus } from 'containers/Menus'
import { Header } from 'containers/Header'
import { Content } from 'containers/Content'

import { Container } from './Layout.styles'
import { FirstAccess } from 'containers/FirstAccess'

export function Layout() {
  const { user } = useAuth()

  return (
    <Suspense fallback={<Loading />}>
      <Container role="container">
        {user && !user.first_access ? (
          <>
            <Menus />
            <Header />
          </>
        ) : (
          user && user.first_access && <FirstAccess />
        )}

        {
          // Não redenrizar o content se tiver usuario logado e ele for primeiro acesso
          user && user.first_access ? <></> : <Content isAuth={!!user} />
        }
      </Container>
    </Suspense>
  )
}
