import { createGlobalStyle, css } from 'styled-components'

import { rgba } from 'polished'

export default createGlobalStyle`
  ${props => css`
    // Dropdown
    .ant-dropdown {
      .ant-dropdown-menu {
        background-color: ${props.theme.colors.global.neutral.white};
        border-color: ${props.theme.colors.global.neutral.lightest};
        border-style: solid;
        border-width: 2px;
        border-radius: 12px;
        box-shadow: unset;
        overflow: auto;
        padding: 0;

        .ant-dropdown-menu-item {
          align-items: center;
          color: ${props.theme.colors.global.neutral.dark};
          font-weight: ${props.theme.font.weight.bold};
          font-size: ${props.theme.font.size.micro};
          font-family: ${props.theme.font.family.base};
          line-height: ${props.theme.line.height.large};
          display: flex;
          justify-content: space-between;
          padding: 9px 14px;

          &:hover {
            background-color: ${rgba(
              props.theme.colors.global.neutral.light,
              0.16
            )};
          }

          div {
            div {
              align-items: center;
              display: flex;
            }
          }

          a {
            align-items: center;
            color: ${props.theme.colors.global.neutral.dark};
            display: flex;
            flex: 1;
            justify-content: space-between;

            div {
              div {
                align-items: center;
                display: flex;
              }
            }
          }

          &[color='negative'] {
            color: ${props.theme.colors.global.negative.pure};

            a {
              color: ${props.theme.colors.global.negative.pure};
            }
          }
        }

        .ant-dropdown-menu-item-divider {
          background-color: ${props.theme.colors.global.neutral.lightest};
          height: 2px;
          margin: 0;
        }
      }
    }

    // Form > Select
    .ant-select-dropdown {
      border: 1px solid ${props.theme.colors.global.neutral.medium};
      border-radius: ${props.theme.border.radius.micro};
      box-shadow: unset;
      padding: 0;

      .rc-virtual-list-holder {
        max-height: 253px;

        .rc-virtual-list-holder-inner {
          .ant-select-item {
            min-height: 39px;

            &:not(:first-child) {
              border-top: 1px solid transparent;
            }

            &.ant-select-item-option {
              border-bottom: 1px solid transparent;

              color: ${props.theme.colors.global.neutral.medium};
              font-weight: ${props.theme.font.weight.bold};
              font-size: ${props.theme.font.size.micro};
              font-family: ${props.theme.font.family.base};
              line-height: 39px;

              &:hover {
                &:last-child {
                  border-bottom: 1px solid transparent;
                }

                background-color: ${props.theme.colors.global.neutral.lightest};
                border-color: ${props.theme.colors.global.neutral.medium};
              }

              &.ant-select-item-option-selected {
                background-color: transparent;
                color: ${props.theme.colors.highlight.pure};

                &:hover {
                  background-color: ${props.theme.colors.global.neutral
                    .lightest};
                }
              }

              &.ant-select-item-option-active {
                background-color: transparent;

                &:hover {
                  background-color: ${props.theme.colors.global.neutral
                    .lightest};
                }
              }
            }
          }
        }
      }
    }

    // Tooltip
    .ant-tooltip {
      max-width: 270px;

      .ant-tooltip-inner {
        background-color: ${props.theme.colors.global.neutral.dark};
        border-radius: 4px;
        box-shadow: ${props.theme.shadow.level.two};

        padding: 16px;
      }

      .ant-tooltip-arrow {
        bottom: -18px;
        height: 40px;
        width: 40px;

        .ant-tooltip-arrow-content {
          background-color: transparent;
          box-shadow: unset;
          transform: unset;

          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid ${props.theme.colors.global.neutral.dark};
          height: 0;
          width: 0;
        }
      }
    }
  `}
`
