import { useEffect } from 'react'

import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi'

import { useToast, ToastMessage } from 'hooks/useToast'

import { Container } from './Message.styles'

interface Props {
  message: ToastMessage
  style: any
}

const icons = {
  info: <FiInfo />,
  error: <FiAlertCircle />,
  success: <FiCheckCircle />
}

export function Message({ message, style }: Props) {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, 30000)

    return () => {
      clearTimeout(timer)
    }
  }, [message.id, removeToast])

  return (
    <Container
      type={message.type}
      description={message.description}
      style={style}
      data-testid="toast-message"
    >
      {icons[message.type]}

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </button>
    </Container>
  )
}
