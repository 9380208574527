import { useCallback, useEffect, useRef } from 'react'

import { Link, useLocation } from 'react-router-dom'

import useMock from 'hooks/useMock'

import { Container, ItemMenu, ItemSubmenu, Menu, Submenu } from './Menus.styles'
import { useAuth } from 'hooks/useAuth'

export function Menus() {
  const { user } = useAuth()
  const { newMenu: menu } = useMock()
  const { pathname } = useLocation()
  const itemMenuRefs = useRef<HTMLElement[]>([])

  const newMenu = menu.map(m => {
    const submenu = m.submenu.items.map((s: any) => ({
      ...s,
      allowed: !!user.roles.find(a => s.roles?.includes(a))
    }))
    return {
      ...m,
      allowed: !!user.roles.find((a: any) => m.roles?.includes(a)),
      submenu: {
        ...m.submenu,
        items: submenu
      }
    }
  })

  useEffect(() => {
    const indexMenu = newMenu.findIndex(n => {
      const sub = n.submenu.items.find(s => {
        const reg = new RegExp(`^${s.link}$`, 'i')
        const has = reg.test(pathname)

        if (!s.link) return false

        return has
      })
      return sub
    })

    itemMenuRefs.current.forEach((f, ix) => {
      if (ix === indexMenu) {
        f.classList.add('menu-item-active')
      }
    })
  }, [newMenu, pathname])

  const isActive = useCallback(
    (link: string, indexMenu: number) => {
      const reg = new RegExp(`^${link}$`, 'i')
      const has = reg.test(pathname)

      if (!link) return false

      if (has) {
        itemMenuRefs.current.forEach(f => {
          f.classList.remove('menu-item-active')
        })

        itemMenuRefs.current.forEach((f, ix) => {
          if (ix === indexMenu) {
            f.classList.add('menu-item-active')
          }
        })
      }

      return has
    },
    [pathname]
  )

  return (
    <Container>
      <Menu>
        {newMenu.map(
          (m, index) =>
            m.allowed && (
              <ItemMenu
                className="menu-item"
                key={index}
                disabled={m.disabled}
                ref={ref => {
                  itemMenuRefs.current[index] = ref as HTMLElement
                }}
              >
                <a>
                  <m.icon className="icon-svg" />
                </a>

                {!m.disabled && (
                  <Submenu className="submenu">
                    <div className="title">
                      <m.icon className="icon-svg-submenu" />

                      <h1>{m.submenu.title}</h1>
                    </div>

                    <ul>
                      {m.submenu.items.map(
                        (s: any, i: any) =>
                          s.allowed && (
                            <ItemSubmenu
                              key={i}
                              disabled={s.disabled}
                              isActive={isActive(s.link, index)}
                            >
                              <Link
                                to={!s.disabled ? s.link : ''}
                                data-collect={s?.dataAtribute}
                              >
                                {s.title}
                              </Link>
                            </ItemSubmenu>
                          )
                      )}
                    </ul>
                  </Submenu>
                )}
              </ItemMenu>
            )
        )}
      </Menu>
    </Container>
  )
}
