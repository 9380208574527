import styled, { css } from 'styled-components'

interface Props {
  isAuth: boolean
}

export const Body = styled.main<Props>`
  background-color: ${props => props.theme.colors.background};
  width: 100%;
  padding: 40px;
  overflow-y: auto;

  ${props =>
    !props.isAuth &&
    css`
      height: 100vh;
    `}

  ${props =>
    props.isAuth &&
    css`
      height: calc(100vh - 80px);
      margin-top: 80px;
    `}
`
