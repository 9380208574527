import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: ${props => props.theme.colors.highlight.pure};
  z-index: 100;

  height: 80px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${props => props.theme.spacing.stack.small};
`

export const Logo = styled.img``

export const Content = styled.div`
  display: flex;
  align-items: center;
`
