import { memo } from 'react'

import { Typography } from './Heading.styles'

/* Types:
 * - Form
 * - FormSmall
 * - Tiny
 * - Small
 * - Medium
 * - Large
 * - Huge
 * - Giant
 */

interface Props {
  type:
    | 'form'
    | 'formSmall'
    | 'tiny'
    | 'small'
    | 'medium'
    | 'large'
    | 'huge'
    | 'giant'
  children: string
}

const Heading = memo(function Heading({ type, children }: Props) {
  return (
    <Typography role="heading" type={type}>
      {children}
    </Typography>
  )
})

export { Heading }
