export enum ROLES {
  'admin' = 'admin',
  'backoffice' = 'backoffice',
  'farming' = 'farming',
  'financial' = 'financial',
  'fiscal' = 'fiscal',
  'hunting' = 'hunting',
  'onboarding' = 'onboarding',
  'seller' = 'seller',
  'setup' = 'setup',
  'suporte' = 'suporte',
  'treinamento' = 'treinamento',
  'incubadora' = 'incubadora',
  'atendimento' = 'atendimento',
  'qualidade' = 'qualidade',
  'regulatorio' = 'regulatorio'
}
