import styled, { css } from 'styled-components'

import loginImg from 'assets/login.png'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;
`

export const Head = styled.div`
  font-family: ${props => props.theme.font.family.base};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size.tiny};
  line-height: ${props => props.theme.line.height.large};
  margin-bottom: ${props => props.theme.spacing.stack.small};
  color: #71778d;
  text-align: center;
`

export const LoginImg = styled.div`
  background-image: url(${loginImg});
  height: 100vh;
  background-size: cover;
  background-position: bottom;
`
export const InputStyle = styled.div`
  input {
    height: 56px;
    background-color: ${props => props.theme.colors.background};
  }
`

export const ForgotPasswords = styled.a`
  font-family: ${props => props.theme.font.family.base};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.micro};
  line-height: ${props => props.theme.line.height.large};
  color: #037236;
`

export const PasswordRequirements = styled.div`
  width: 100%;
  background: #f4f4f6;
  border-radius: 8px;
  padding: 20px;

  .head {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    /* Global/Content/*500 */

    color: #6a7086;
    margin-bottom: 8px;
  }

  .body {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    /* Theme/RD/Brand/*500 */

    color: #037236;
    margin-bottom: 8px;
    span {
      margin-right: 0.25rem;
    }
  }
`
interface ColorProps {
  error: boolean
}

export const CheckColor = styled.span<ColorProps>`
  color: ${props => props.theme.colors.brand.dark};

  ${props =>
    props.error &&
    css`
      color: ${props.theme.colors.global.negative.pure};
    `}
`
