import React, { createContext, useCallback, useState, useContext } from 'react'

import jwt from 'jsonwebtoken'

import { addSeconds } from 'date-fns'

import {
  ApolloClient,
  NormalizedCacheObject,
  useMutation
} from '@apollo/client'

import { client } from 'services/graphql'
import { CREATE_AUTHENTICATE } from 'services/graphql/mutations/Auth/Authenticate'
import { CREATE_AUTHENTICATE_REFRESH } from 'services/graphql/mutations/Auth/AuthenticateRefresh'

interface IUser {
  name: string
  username: string
  roles: string[]
  email: string
  sellerId: string
  first_access: boolean
}

interface AuthData {
  accessToken: string
  expiresIn: number
  refreshExpiresIn: number
  refreshToken: string
}

interface AuthState {
  token: string
  expiresIn: string
  refreshToken: string
  refreshExpiresIn: string
  user: IUser
}

interface SignInCredentials {
  username: string
  password: string
}

interface AuthContextData {
  token: string
  expiresIn: number
  tokenRefresh: string
  refreshExpiresIn: number
  user: IUser
  setUserAuth(auth: AuthData): void
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  refreshToken(): Promise<void>
}

interface DecodeJWT {
  name: string
  username: string
  roles: string[]
  first_access: boolean
  email: string
  seller_name: string
  resource_access: {
    'bff-marketplace-financeiro': {
      roles: string[]
    }
  }
  preferred_username: string
}

interface IAuthProvider {
  setClient?: React.Dispatch<
    React.SetStateAction<{
      client: () => ApolloClient<NormalizedCacheObject>
    }>
  >
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC<IAuthProvider> = ({ children, setClient }) => {
  const [createAuthenticate] = useMutation(CREATE_AUTHENTICATE)
  const [createAuthenticateRefresh] = useMutation(CREATE_AUTHENTICATE_REFRESH)

  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@RDMarketplace:user')
    const token = localStorage.getItem('@RDMarketplace:token')
    const refreshToken = localStorage.getItem('@RDMarketplace:refreshToken')
    const expiresIn = localStorage.getItem('@RDMarketplace:expiresIn')
    const refreshExpiresIn = localStorage.getItem(
      '@RDMarketplace:refreshExpiresIn'
    )

    if (user && token && refreshToken && expiresIn && refreshExpiresIn) {
      return {
        token,
        expiresIn: expiresIn,
        refreshToken,
        refreshExpiresIn: refreshExpiresIn,
        user: JSON.parse(user)
      }
    }

    return {} as AuthState
  })

  const setUserAuth = useCallback(
    (auth: AuthData) => {
      const {
        accessToken,
        expiresIn,
        refreshToken: rToken,
        refreshExpiresIn
      } = auth

      const {
        roles,
        name,
        email,
        preferred_username,
        first_access,
        seller_name
      } = jwt.decode(accessToken, {
        json: true
      }) as DecodeJWT

      const user = {
        name,
        roles,
        email,
        username: preferred_username,
        sellerId: seller_name,
        first_access: Boolean(first_access)
      }

      const expiresInTime = addSeconds(new Date(), expiresIn).getTime()
      const refreshExpiresInTime = addSeconds(
        new Date(),
        refreshExpiresIn
      ).getTime()

      localStorage.setItem('@RDMarketplace:token', accessToken)
      localStorage.setItem('@RDMarketplace:refreshToken', rToken)
      localStorage.setItem('@RDMarketplace:expiresIn', String(expiresInTime))
      localStorage.setItem(
        '@RDMarketplace:refreshExpiresIn',
        String(refreshExpiresInTime)
      )
      localStorage.setItem('@RDMarketplace:user', JSON.stringify(user))

      setData({
        user,
        token: accessToken,
        expiresIn: String(expiresInTime),
        refreshExpiresIn: String(refreshExpiresInTime),
        refreshToken: rToken
      })

      if (setClient) {
        setClient({
          client
        })
      }
    },
    [setClient]
  )

  const signIn = useCallback(
    async ({ username, password }: SignInCredentials) => {
      const { data } = await createAuthenticate({
        variables: {
          username,
          password
        }
      })

      setUserAuth(data.authenticate)
    },
    [createAuthenticate, setUserAuth]
  )

  const refreshToken = useCallback(async () => {
    const { data: dataAuthRefresh } = await createAuthenticateRefresh({
      variables: {
        token: localStorage.getItem('@RDMarketplace:refreshToken')
      }
    })
    setUserAuth(dataAuthRefresh.refreshAuth)
  }, [createAuthenticateRefresh, setUserAuth])

  const signOut = useCallback(() => {
    localStorage.removeItem('@RDMarketplace:user')
    localStorage.removeItem('@RDMarketplace:token')
    localStorage.removeItem('@RDMarketplace:refreshToken')
    localStorage.removeItem('@RDMarketplace:expiresIn')
    localStorage.removeItem('@RDMarketplace:refreshExpiresIn')
    if (setClient) {
      setClient({
        client
      })
    }

    setData({} as AuthState)
  }, [setClient])

  return (
    <AuthContext.Provider
      value={{
        setUserAuth,
        signIn,
        signOut,
        refreshToken,
        user: data.user,
        expiresIn: Number(data.expiresIn),
        refreshExpiresIn: Number(data.refreshExpiresIn),
        token: data.token,
        tokenRefresh: data.refreshToken
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  return context
}

export { AuthProvider, useAuth }
