import React, { createContext, useContext, useCallback, useState } from 'react'

import { v4 } from 'uuid'

import { Toast } from 'components/Toast'

export interface ToastMessage {
  id: string
  type: 'success' | 'error' | 'info'
  title: string
  description: string
}

interface ToastContexData {
  addToast(message: Omit<ToastMessage, 'id'>): void
  removeToast(id: string): void
  messages: ToastMessage[]
}

const ToastContext = createContext<ToastContexData>({} as ToastContexData)

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const addToast = useCallback(
    ({ title, description, type }: Omit<ToastMessage, 'id'>) => {
      const toast = {
        id: v4(),
        title,
        description,
        type
      }

      setMessages(state => [...state, toast])
    },
    []
  )

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(s => s.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast, messages }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  )
}

function useToast(): ToastContexData {
  return useContext(ToastContext)
}

export { ToastProvider, useToast }
