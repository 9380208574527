import iconLogo from 'assets/logo.svg'

import { SelectOptions } from './SelectOptions'

import { Container, Logo, Content } from './Header.styles'
import { Link } from 'react-router-dom'

export function Header() {
  return (
    <Container>
      <Link to="/">
        <Logo src={iconLogo} />
      </Link>

      <Content>
        <SelectOptions />
      </Content>
    </Container>
  )
}
