import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
  ${props => css`
    .swal-overlay--show-modal,
    .swal-modal {
      animation: none !important;
    }

    .swal-modal {
      .swal-title {
        color: ${props.theme.colors.global.neutral.black};
        font-family: ${props.theme.font.family.headings};
        font-weight: ${props.theme.font.weight.bold};
      }

      .swal-text {
        color: ${props.theme.colors.global.neutral.black};
        display: block;
        font-family: ${props.theme.font.family.base};
        font-size: ${props.theme.font.size.tiny};
        line-height: ${props.theme.line.height.tiny};
        max-width: 100%;
        text-align: center;
      }

      .swal-button {
        font-family: ${props.theme.font.family.base};
        font-size: ${props.theme.font.size.nano};
        font-weight: ${props.theme.font.weight.bold};
      }
    }
  `}
`
