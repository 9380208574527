import 'antd/dist/antd.css'

import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  a {
    color: ${props => props.theme.colors.brand.pure};
    text-decoration: unset;

    &:hover {
      color: ${props => props.theme.colors.brand.dark};
    }
  }
`
