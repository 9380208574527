import styled from 'styled-components'

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLoading = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`

export const FirstEllipsis = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #29754d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  left: 8px;
  animation: first-ellipsis 0.6s infinite;

  @keyframes first-ellipsis {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
`

export const SecondEllipsis = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #29754d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  left: 8px;
  animation: second-ellipsis 0.6s infinite;

  @keyframes second-ellipsis {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
`

export const ThirdEllipsis = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #29754d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  left: 32px;
  animation: second-ellipsis 0.6s infinite;

  @keyframes second-ellipsis {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
`

export const FourthEllipsis = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #29754d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  left: 56px;
  animation: fourth-ellipsis 0.6s infinite;

  @keyframes fourth-ellipsis {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }
`
