import { lazy } from 'react'

import { IRouteProps } from './CustomRoute.interface'

import { sellerRoutes } from './Seller'
import { invoicesRoutes } from './Invoices'
import { financialRoutes } from './Financial'
import { conciliationRoutes } from './Conciliation'
import { transferRoutes } from './TransferBetweenSchedules'
import { settingsRoutes } from './Settings'
import { regulatoryRoutes } from './Regulatorio'
import { reportRoutes } from './Reports'

const NotFound = lazy(() =>
  import('pages/NotFound').then(module => ({
    default: module.NotFound
  }))
)

const Login = lazy(() =>
  import('pages/Login').then(module => ({
    default: module.Login
  }))
)

const ForgotPassword = lazy(() =>
  import('pages/ForgotPassword').then(module => ({
    default: module.ForgotPassword
  }))
)

const Dashboard = lazy(() =>
  import('pages/Dashboard').then(module => ({
    default: module.Dashboard
  }))
)

const mainRoutes: IRouteProps[] = [
  {
    path: '/forgot-password',
    exact: true,
    name: 'ForgotPassword',
    component: ForgotPassword,
    isPrivate: false
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
    isPrivate: false
  },
  {
    path: '/404',
    exact: true,
    name: 'NotFound',
    component: NotFound,
    isPrivate: true
  },
  {
    path: '/',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    isPrivate: true
  }
]

const routes: IRouteProps[] = mainRoutes.concat(
  sellerRoutes,
  invoicesRoutes,
  financialRoutes,
  conciliationRoutes,
  transferRoutes,
  settingsRoutes,
  regulatoryRoutes,
  reportRoutes
)

export default routes
