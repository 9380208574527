import { useEffect } from 'react'

import { isAfter } from 'date-fns'

import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

import { Breadcrumb } from 'components/Breadcrumb'

import { useMutation } from '@apollo/client'
import { CREATE_AUTHENTICATE_REFRESH } from 'services/graphql/mutations/Auth/AuthenticateRefresh'
import { IRouteProps } from './CustomRoute.interface'

export function CustomRoute({
  component: Component,
  isPrivate = false,
  roles,
  breadcrumb,
  ...rest
}: IRouteProps) {
  const { user, expiresIn, refreshExpiresIn, signOut, setUserAuth } = useAuth()

  const [createAuthenticateRefresh] = useMutation(CREATE_AUTHENTICATE_REFRESH)

  let allowed = true

  if (roles?.length && user) {
    allowed = !!user.roles.find(a => roles.includes(a))
  }

  const tokenExpire = isAfter(new Date(), expiresIn)
  const tokenRefreshExpire = isAfter(new Date(), refreshExpiresIn)

  useEffect(() => {
    if (tokenRefreshExpire) {
      signOut()
    }

    if (tokenExpire && !tokenRefreshExpire) {
      createAuthenticateRefresh({
        variables: {
          token: localStorage.getItem('@RDMarketplace:refreshToken')
        }
      }).then(response => {
        setUserAuth(response.data.refreshAuth)
      })
    }
  }, [
    tokenExpire,
    tokenRefreshExpire,
    signOut,
    createAuthenticateRefresh,
    setUserAuth
  ])

  return (
    <ReactDOMRoute
      {...rest}
      render={props => {
        return allowed ? (
          isPrivate === !!user ? (
            <>
              {breadcrumb && (
                <Breadcrumb items={breadcrumb.items} title={breadcrumb.title} />
              )}

              <Component {...props} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: user ? '/' : '/login',
                state: { from: props.location }
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}
