import { AiOutlineCheckCircle, AiOutlineReload } from 'react-icons/ai'
import iconLeading from 'assets/iconLeading.svg'
import iconStep from 'assets/iconStep.svg'
import iconLink from 'assets/iconLink.svg'
import iconDownloadSaveUpload from 'assets/iconDownloadSaveUpload.svg'
import iconExclude from 'assets/iconX.svg'

import { ReactSVG } from './Icon.styles'

interface Props {
  icon?:
    | 'leading'
    | 'step'
    | 'link'
    | 'download'
    | 'exclude'
    | 'checked'
    | 'progress'
}

export function Icon({ icon }: Props) {
  switch (icon) {
    case 'leading':
      return <ReactSVG src={iconLeading} data-testid="icon" />

    case 'step':
      return <ReactSVG src={iconStep} data-testid="icon" />

    case 'link':
      return <ReactSVG src={iconLink} data-testid="icon" />

    case 'download':
      return <ReactSVG src={iconDownloadSaveUpload} data-testid="icon" />

    case 'exclude':
      return <ReactSVG src={iconExclude} data-testid="icon" />

    case 'checked':
      return <AiOutlineCheckCircle size={16} />

    case 'progress':
      return <AiOutlineReload size={16} />

    default:
      return null
  }
}
