import { lazy } from 'react'
import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

const Dimp = lazy(() =>
  import('pages/Regulatory/Dimp').then(module => ({
    default: module.Dimp
  }))
)
export const ROUTE_DIMP = '/regulatory/dimp'

export const regulatoryRoutes: IRouteProps[] = [
  {
    path: ROUTE_DIMP,
    exact: true,
    name: 'DIMP',
    component: Dimp,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.regulatorio],
    breadcrumb: {
      items: [
        {
          label: 'Regulatório'
        }
      ],
      title: 'DIMP'
    }
  }
]
