import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

import {
  ROUTE_LIST_ORDER,
  ROUTE_BLOCK,
  ROUTE_TRANSFER_RECEIVED,
  ROUTE_TRANSFER_RECEIVE,
  ROUTE_MAINTENANCE_ORDER,
  ROUTE_CREATE_ORDER_MAINTENANCE,
  ROUTE_MAINTENANCE_COMMISSION,
  ROUTE_ADJUSTMENT_BY_ORDER,
  ROUTE_ADJUSTMENT_LIST,
  ROUTE_CREATE_ADJUSTMENT,
  ROUTE_EDIT_ADJUSTMENT,
  ROUTE_MAINTENANCE_ORDER_FULL,
  ROUTE_ORDER_REFUND,
  ROUTE_MASSIVE_REFUND,
  ROUTE_ORDER_MAINTENANCE_STATUS,
  ROUTE_ORDER_MIGRATE,
  ROUTE_ADJUSTMENT_CONSOLIDATED_LIST
} from './paths'

const ListOrder = lazy(() =>
  import('pages/Financial/Order/List').then(module => ({
    default: module.List
  }))
)

const TransferBlock = lazy(() =>
  import('pages/Financial/Transfer/Block').then(module => ({
    default: module.Block
  }))
)

const TransferReceived = lazy(() =>
  import('pages/Financial/Transfer/Received').then(module => ({
    default: module.Received
  }))
)

const TransferReceive = lazy(() =>
  import('pages/Financial/Transfer/Receive').then(module => ({
    default: module.Receive
  }))
)

const OrderMaintenance = lazy(() =>
  import('pages/Financial/Order/Maintenance/Old').then(module => ({
    default: module.Maintenance
  }))
)

const OrderMaintenanceNew = lazy(() =>
  import('pages/Financial/Order/Maintenance/New').then(module => ({
    default: module.Maintenance
  }))
)

const CreateMaintenanceOrder = lazy(() =>
  import('pages/Financial/Order/Maintenance/Create').then(module => ({
    default: module.CreateOrderReport
  }))
)

const CommissionMaintenance = lazy(() =>
  import('pages/Financial/Commission/Maintenance').then(module => ({
    default: module.Maintenance
  }))
)

const AdjustmentByOrder = lazy(() =>
  import('pages/Financial/Adjustment/ByOrder').then(module => ({
    default: module.AdjustmentByOrder
  }))
)

const AdjustmentList = lazy(() =>
  import('pages/Financial/Adjustment/List').then(module => ({
    default: module.AdjustmentList
  }))
)

const AdjustmentConsolidatedList = lazy(() =>
  import('pages/Financial/Adjustment/ConsolidatedList').then(module => ({
    default: module.AdjustmentConsolidatedList
  }))
)

const AdjustmentRegistration = lazy(() =>
  import('pages/Financial/Adjustment/Registration').then(module => ({
    default: module.AdjustmentRegistration
  }))
)

const CancelOrder = lazy(() =>
  import('pages/Invoices/CancelOrder').then(module => ({
    default: module.Cancel
  }))
)

const MassiveRefund = lazy(() =>
  import('pages/Financial/Refund/Massive').then(module => ({
    default: module.MassiveRefund
  }))
)

const MaintenanceStatus = lazy(() =>
  import('pages/Financial/Order/Maintenance/Status').then(module => ({
    default: module.MaintenanceStatus
  }))
)

const Migrate = lazy(() =>
  import('pages/Financial/Order/Migrate').then(module => ({
    default: module.Migrate
  }))
)

export const financialRoutes: IRouteProps[] = [
  {
    path: ROUTE_LIST_ORDER,
    exact: true,
    name: 'List Order',
    component: ListOrder,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Pedidos'
    }
  },
  {
    path: ROUTE_ORDER_MIGRATE,
    exact: true,
    name: 'Migrate Orders',
    component: Migrate,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Migrar Pedidos'
    }
  },
  {
    path: `${ROUTE_LIST_ORDER}/:seller`,
    exact: true,
    name: 'List Order',
    component: ListOrder,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Pedidos'
    }
  },
  {
    path: ROUTE_BLOCK,
    exact: true,
    name: 'Block',
    component: TransferBlock,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Bloqueio do Repasse'
    }
  },
  {
    path: ROUTE_TRANSFER_RECEIVED,
    exact: true,
    name: 'Received',
    component: TransferReceived,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Repasses recebidos'
    }
  },
  {
    path: ROUTE_TRANSFER_RECEIVE,
    exact: true,
    name: 'Receive',
    component: TransferReceive,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Repasses a receber'
    }
  },
  {
    path: ROUTE_MAINTENANCE_ORDER,
    exact: true,
    name: 'Manutenção de Faturamento',
    component: OrderMaintenance,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Manutenção de Faturamento'
    }
  },
  {
    path: ROUTE_MAINTENANCE_ORDER_FULL,
    exact: true,
    name: 'Manutenção de Faturamento',
    component: OrderMaintenanceNew,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Manutenção de Faturamento'
    }
  },
  {
    path: ROUTE_CREATE_ORDER_MAINTENANCE,
    exact: true,
    name: 'Criar manutenção',
    component: CreateMaintenanceOrder,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Criar manutenção'
    }
  },
  {
    path: ROUTE_MAINTENANCE_COMMISSION,
    exact: true,
    name: 'Manutenção de Comissão',
    component: CommissionMaintenance,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Manutenção de Comissão'
    }
  },
  {
    path: ROUTE_ADJUSTMENT_BY_ORDER,
    exact: true,
    name: 'Adjustment By Order',
    component: AdjustmentByOrder,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Ajustes'
    }
  },
  {
    path: `${ROUTE_ADJUSTMENT_LIST}/:cycle?/:sellerId?`,
    exact: true,
    name: 'Adjustment',
    component: AdjustmentList,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.fiscal],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Ajustes'
    }
  },
  {
    path: ROUTE_ADJUSTMENT_CONSOLIDATED_LIST,
    exact: true,
    name: 'Adjustment Consolidated',
    component: AdjustmentConsolidatedList,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial, ROLES.fiscal],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Ajustes Consolidados'
    }
  },
  {
    path: ROUTE_CREATE_ADJUSTMENT,
    exact: true,
    name: 'Create Adjustment',
    component: AdjustmentRegistration,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        },
        {
          label: 'Ajustes',
          route: ROUTE_ADJUSTMENT_BY_ORDER
        },
        {
          label: 'Listagem',
          route: ROUTE_ADJUSTMENT_LIST
        }
      ],
      title: 'Criar ajuste'
    }
  },
  {
    path: `${ROUTE_EDIT_ADJUSTMENT}/:id`,
    exact: true,
    name: 'Edit Adjustment',
    component: AdjustmentRegistration,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        },
        {
          label: 'Ajustes',
          route: ROUTE_ADJUSTMENT_BY_ORDER
        },
        {
          label: 'Listagem',
          route: ROUTE_ADJUSTMENT_LIST
        }
      ],
      title: 'Editar ajuste'
    }
  },
  {
    path: `${ROUTE_ORDER_REFUND}/:nrPedido?`,
    exact: true,
    name: 'Estornar',
    component: CancelOrder,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.atendimento,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Estorno'
    }
  },
  {
    path: `${ROUTE_MASSIVE_REFUND}/:nrPedido?`,
    exact: true,
    name: 'Cancelamento',
    component: MassiveRefund,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Manutenção de Cancelamento'
    }
  },
  {
    path: ROUTE_ORDER_MAINTENANCE_STATUS,
    exact: true,
    name: 'Alteração Status',
    component: MaintenanceStatus,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Financeiro'
        }
      ],
      title: 'Alteração Status Pedido'
    }
  }
]
