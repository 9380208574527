const path = '/financial'

export const ROUTE_MAINTENANCE_ORDER = `${path}/maintenance-order`
export const ROUTE_MAINTENANCE_ORDER_FULL = `${path}/maintenance-order-full`
export const ROUTE_MAINTENANCE_ADJUST = `${path}/maintenance-adjust`
export const ROUTE_MAINTENANCE_COMMISSION = `${path}/maintenance-commission`

export const ROUTE_LIST_ORDER = '/financial/order'
export const ROUTE_CREATE_ORDER_MAINTENANCE =
  '/financial/maintenance-order/create'

export const ROUTE_ADJUSTMENT_BY_ORDER = '/financial/adjustment/order'
export const ROUTE_ADJUSTMENT_LIST = '/financial/adjustment/list'
export const ROUTE_ADJUSTMENT_CONSOLIDATED_LIST =
  '/financial/adjustment/consolidated/list'
export const ROUTE_CREATE_ADJUSTMENT = '/financial/adjustment/create'
export const ROUTE_EDIT_ADJUSTMENT = '/financial/adjustment/edit'

export const ROUTE_BLOCK = '/financial/transfer-block'
export const ROUTE_TRANSFER_RECEIVED = '/financial/transfer-received'
export const ROUTE_TRANSFER_RECEIVE = '/financial/transfer-receive'

export const ROUTE_ORDER_REFUND = `${path}/refund/order`
export const ROUTE_MASSIVE_REFUND = '/financial/refund/massive'

export const ROUTE_ORDER_MAINTENANCE_STATUS = `${path}/maintenance-order-status`

export const ROUTE_ORDER_MIGRATE = `${path}/order/migrate`
