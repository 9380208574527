import { ReactComponent as IconTrumpet } from 'assets/iconTrumpet.svg'
import { ReactComponent as IconCalendar } from 'assets/iconCalendar.svg'
import { ReactComponent as IconMoney } from 'assets/iconMoney.svg'
import { ReactComponent as IconNf } from 'assets/iconNF.svg'
import { ReactComponent as IconService } from 'assets/iconService.svg'
import { ReactComponent as IconTimer } from 'assets/iconTimer.svg'
import { ReactComponent as IconSettings } from 'assets/iconSettings.svg'

import { AiOutlineLineChart } from 'react-icons/ai'

import {
  ROUTE_DATA_SELLER,
  ROUTE_MULTISELLER_LIST,
  ROUTE_SELLER_BRANCH_LIST,
  ROUTE_SELLER_LIST
} from 'routes/Seller/Private/paths'

import {
  ROUTE_INCOME,
  ROUTE_INCOME_TAX,
  ROUTE_INVOICE,
  ROUTE_INVOICE_PROCESSING_REPORT,
  ROUTE_REPORT_TAX
} from 'routes/Invoices/paths'

import {
  ROUTE_MAINTENANCE_COMMISSION,
  ROUTE_MAINTENANCE_ORDER_FULL,
  ROUTE_MASSIVE_REFUND,
  ROUTE_ORDER_REFUND,
  ROUTE_ORDER_MAINTENANCE_STATUS,
  ROUTE_ORDER_MIGRATE,
  ROUTE_ADJUSTMENT_LIST,
  ROUTE_ADJUSTMENT_CONSOLIDATED_LIST,
  ROUTE_ADJUSTMENT_BY_ORDER
} from 'routes/Financial/paths'

import {
  ROUTE_DELETE_CONCILIATION,
  ROUTE_LIST_CONCILIATION,
  ROUTE_REPROCESS_ORDER
} from 'routes/Conciliation/paths'

import {
  ROUTE_ACCOUNT_WITHDRAWAL,
  ROUTE_CREATE_TRANSFER,
  ROUTE_GET_BALANCE
} from 'routes/TransferBetweenSchedules/paths'

import {
  ROUTE_CREATE_REPRESENTATIVE,
  ROUTE_LIST_USERS,
  ROUTE_LIST_INTEGRATOR
} from 'routes/Settings'

import { ROLES } from 'utils/roles'
import { ROUTE_DIMP } from 'routes/Regulatorio'
import {
  ROUTE_PROCESS_TRANSFER,
  ROUTE_TRANSFER_REPORT,
  ROUTE_TRANSFER_REPORT_CONSOLIDATED
} from 'routes/Reports'

const useMock = () => {
  return {
    newMenu: [
      {
        icon: IconService,
        title: 'Dados Cadastrais',
        disabled: false,
        roles: [ROLES.seller],
        submenu: {
          title: 'Dados Cadastrais',
          items: [
            {
              title: 'Exibir',
              link: ROUTE_DATA_SELLER,
              disabled: false,
              roles: [ROLES.seller]
            }
          ]
        }
      },
      {
        icon: IconService,
        title: 'Seller',
        disabled: false,
        roles: [
          ROLES.admin,
          ROLES.financial,
          ROLES.setup,
          ROLES.farming,
          ROLES.hunting,
          ROLES.backoffice,
          ROLES.suporte,
          ROLES.treinamento,
          ROLES.incubadora,
          ROLES.qualidade,
          ROLES.fiscal,
          ROLES.regulatorio
        ],
        submenu: {
          title: 'Seller',
          items: [
            {
              title: 'Gerenciar Multi Seller',
              link: ROUTE_MULTISELLER_LIST,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal
              ]
            },
            {
              title: 'Listar Seller',
              link: ROUTE_SELLER_LIST,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal,
                ROLES.regulatorio
              ]
            },
            {
              title: 'Filiais',
              link: ROUTE_SELLER_BRANCH_LIST,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal
              ]
            }
          ]
        }
      },
      {
        icon: IconNf,
        title: 'Notas Fiscais',
        disabled: false,
        roles: [
          ROLES.admin,
          ROLES.financial,
          ROLES.farming,
          ROLES.setup,
          ROLES.hunting,
          ROLES.backoffice,
          ROLES.suporte,
          ROLES.treinamento,
          ROLES.incubadora,
          ROLES.qualidade,
          ROLES.fiscal,
          ROLES.seller
        ],
        submenu: {
          title: 'Notas Fiscais',
          items: [
            {
              title: 'Exibir',
              link: ROUTE_INVOICE,
              dataAtribute: 'menu_nota_fiscal_exibir',
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal,
                ROLES.seller
              ]
            },
            {
              title: 'Manutenção de Faturamento',
              link: ROUTE_MAINTENANCE_ORDER_FULL,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal
              ]
            },
            {
              title: 'Migrar Pedidos',
              link: ROUTE_ORDER_MIGRATE,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Relatório de Faturamento',
              link: ROUTE_INVOICE_PROCESSING_REPORT,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal
              ]
            },
            {
              title: 'Informe de Rendimento',
              link: ROUTE_INCOME,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.farming,
                ROLES.hunting,
                ROLES.setup,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal,
                ROLES.seller
              ]
            },
            {
              title: 'Enviar Informe de Rendimento',
              link: ROUTE_REPORT_TAX,
              disabled: false,
              roles: [ROLES.seller]
            },
            {
              title: 'Acompanhar Informe',
              link: ROUTE_INCOME_TAX,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.farming,
                ROLES.setup,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal
              ]
            }
          ]
        }
      },
      {
        icon: IconMoney,
        title: 'Financeiro',
        disabled: false,
        roles: [
          ROLES.admin,
          ROLES.financial,
          ROLES.setup,
          ROLES.farming,
          ROLES.hunting,
          ROLES.backoffice,
          ROLES.suporte,
          ROLES.treinamento,
          ROLES.incubadora,
          ROLES.qualidade,
          ROLES.atendimento,
          ROLES.fiscal
        ],
        submenu: {
          title: 'Financeiro',
          items: [
            {
              title: 'Manutenção de Comissão',
              link: ROUTE_MAINTENANCE_COMMISSION,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Estorno',
              link: ROUTE_ORDER_REFUND,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.atendimento,
                ROLES.fiscal
              ]
            },
            {
              title: 'Manutenção de Cancelamento',
              link: ROUTE_MASSIVE_REFUND,
              disabled: false,
              roles: [
                ROLES.admin,
                ROLES.financial,
                ROLES.setup,
                ROLES.farming,
                ROLES.hunting,
                ROLES.backoffice,
                ROLES.suporte,
                ROLES.treinamento,
                ROLES.incubadora,
                ROLES.qualidade,
                ROLES.fiscal
              ]
            },
            {
              title: 'Alterar Status Pedido',
              link: ROUTE_ORDER_MAINTENANCE_STATUS,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Criar Ajuste',
              link: ROUTE_ADJUSTMENT_BY_ORDER,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Ajustes',
              link: ROUTE_ADJUSTMENT_LIST,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial, ROLES.fiscal]
            },
            {
              title: 'Ajustes Consolidados',
              link: ROUTE_ADJUSTMENT_CONSOLIDATED_LIST,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial, ROLES.fiscal]
            }
          ]
        }
      },
      {
        icon: IconTimer,
        title: 'Agendas',
        disabled: false,
        roles: [ROLES.financial, ROLES.admin, ROLES.fiscal],
        submenu: {
          title: 'Agendas',
          items: [
            {
              title: 'Transferência entre contas',
              link: ROUTE_CREATE_TRANSFER,
              disabled: false,
              roles: [ROLES.financial, ROLES.admin, ROLES.fiscal]
            },
            {
              title: 'Saque de contas',
              link: ROUTE_ACCOUNT_WITHDRAWAL,
              disabled: false,
              roles: [ROLES.financial, ROLES.admin, ROLES.fiscal]
            },
            {
              title: 'Saldo de contas',
              link: ROUTE_GET_BALANCE,
              disabled: false,
              roles: [ROLES.financial, ROLES.admin, ROLES.fiscal]
            }
          ]
        }
      },
      {
        icon: IconCalendar,
        title: 'Conciliação',
        disabled: false,
        roles: [ROLES.admin, ROLES.fiscal, ROLES.financial],
        submenu: {
          title: 'Conciliação',
          items: [
            {
              title: 'Consultar Pedidos',
              link: ROUTE_LIST_CONCILIATION,
              disabled: false,
              roles: [ROLES.admin, ROLES.fiscal, ROLES.financial]
            },
            {
              title: 'Reprocessar Pedido',
              link: ROUTE_REPROCESS_ORDER,
              disabled: false,
              roles: [ROLES.admin, ROLES.fiscal, ROLES.financial]
            },
            {
              title: 'Deletar Pedido',
              link: ROUTE_DELETE_CONCILIATION,
              disabled: false,
              roles: [ROLES.admin, ROLES.fiscal, ROLES.financial]
            }
          ]
        }
      },
      {
        icon: AiOutlineLineChart,
        title: 'Relatórios',
        disabled: false,
        roles: [ROLES.admin, ROLES.financial],
        submenu: {
          title: 'Relatórios',
          items: [
            {
              title: 'Relatório de Repasse',
              link: ROUTE_TRANSFER_REPORT,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Relatório de Repasse Consolidado',
              link: ROUTE_TRANSFER_REPORT_CONSOLIDATED,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Processar Repasse',
              link: ROUTE_PROCESS_TRANSFER,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            }
          ]
        }
      },
      {
        icon: IconTrumpet,
        title: 'Regulatório',
        disabled: false,
        roles: [ROLES.admin, ROLES.financial, ROLES.regulatorio],
        submenu: {
          title: 'Regulatório',
          items: [
            {
              title: 'Dimp',
              link: ROUTE_DIMP,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial, ROLES.regulatorio]
            }
          ]
        }
      },
      {
        icon: IconSettings,
        title: 'Configurações',
        disabled: false,
        roles: [ROLES.admin, ROLES.financial, ROLES.suporte],
        submenu: {
          title: 'Configurações',
          items: [
            {
              title: 'Cadastrar Responsáveis',
              link: ROUTE_CREATE_REPRESENTATIVE,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial]
            },
            {
              title: 'Listar Usuários',
              link: ROUTE_LIST_USERS,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial, ROLES.suporte]
            },
            {
              title: 'Listar Integradoras',
              link: ROUTE_LIST_INTEGRATOR,
              disabled: false,
              roles: [ROLES.admin, ROLES.financial, ROLES.suporte]
            }
          ]
        }
      }
    ],
    data: [
      {
        status: 'Aprovado',
        nRequest: 1660112010,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'Recusado',
        nRequest: 1660112011,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'AgtoPgto',
        nRequest: 1660112012,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Boleto',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'Aprovado',
        nRequest: 1660112013,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Boleto',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'Estornado',
        nRequest: 1660112014,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Boleto',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'Chargeback',
        nRequest: 1660112015,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'Recusado',
        nRequest: 1660112016,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'AgtoPgto',
        nRequest: 1660112017,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'AgtoPgto',
        nRequest: 1660112018,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      },
      {
        status: 'Expirado',
        nRequest: 1660112198,
        dateAndTime: '22/10/2020 às 18:45',
        customer: 'Mariana de Oliveira Souza',
        methodPayment: 'Cartão de crédito parcelado em 3x',
        amountReceivable: 'R$ 1.119,50'
      }
    ],
    status: [
      { value: '1', label: 'Todos' },
      { value: '2', label: 'Estornado' },
      { value: '3', label: 'Aguardando Pgto' },
      { value: '4', label: 'Recusado' },
      { value: '5', label: 'Expirado' },
      { value: '6', label: 'Chargeback' },
      { value: '7', label: 'Aprovado' }
    ],
    summary: [
      {
        title: 'Total de pedidos no período',
        description: '1.925.898 em 2.234 dias',
        extras: '122.345 não entregues (16.23%)'
      },
      {
        title: 'Tempo médio de entrega',
        description: '2.102 dias, 3h e 56m',
        extras: 'De 1.834.987 pedidos entregues'
      },
      {
        title: 'Total vendido',
        description: 'R$ 21.932.028,39',
        extras: '43.27% Cartão • 57.45% Boleto'
      },
      {
        title: 'Ticket médio',
        description: 'R$ 124,14',
        extras: 'De 1.925.898 pedidos no período'
      }
    ],
    headerSelect: {
      name: 'Mauricio Oliveira da Silva',
      items: [
        {
          title: 'Seu perfil',
          link: '',
          icon: null
        }
      ]
    },
    OrderCancel: {
      NPedido: 239238191,
      DataPedido: '24/05/2021 06:25:55',
      Seller: 'Drogaraia',
      Status: 'Em Separação',
      Cliente: {
        Nome: 'Luis Felipe Bulgari',
        Email: 'lfsf@rd.com.br',
        Nascimento: ' 29/04/1990',
        Sexo: 'Masculino',
        TaxNumber: 3484320342,
        Grupo: 'General',
        Endereco: {
          Descricao: 'LUIS FELIPE',
          Cep: 14596077,
          Logradouro: 'Rua Coronel Pedro Vaz',
          Bairro: 'Esmeralda',
          Numero: 1303,
          Complemento: 'S/C',
          Cidade: 'Alagoinha',
          Estado: 'Alagoas',
          Pais: 'Brasil',
          Telefone: 12554322192
        },
        Pagamento: {
          Tipo: 'Cartão de Crédito',
          Bandeira: 'MasterCard',
          Titular: 'Luis Felipe Bulgari',
          NParcelas: 1,
          MesVencimento: 12,
          AnoVencimento: 2030,
          Cartao: 'XXXX-XXXX-XXXX-5932'
        }
      },
      ProductList: [
        {
          Actions: 1,
          SKU: 121923,
          Store: 'DrogaRaia',
          Description: 'Secador de Cabelo',
          Quantity: 2,
          Price: 'R$ 2,50',
          Frete: 'R$ 10,00',
          Total: 'R$ 15,00',
          Reembolso: 'R$ 15,00'
        },
        {
          Actions: 2,
          SKU: 120483,
          Store: 'DrogaRaia',
          Description: 'Medidor de Pressão',
          Quantity: 2,
          Price: 'R$ 2,50',
          Frete: 'R$ 10,00',
          Total: 'R$ 15,00',
          Reembolso: 'R$ 15,00'
        },
        {
          Actions: 3,
          SKU: 390483,
          Store: 'DrogaRaia',
          Description: 'Esparadrapo',
          Quantity: 2,
          Price: 'R$ 2,50',
          Frete: 'R$ 10,00',
          Total: 'R$ 15,00',
          Reembolso: 'R$ 15,00'
        },
        {
          Actions: 4,
          SKU: 121983,
          Store: 'DrogaRaia',
          Description: 'Balança Digital',
          Quantity: 2,
          Price: 'R$ 2,50',
          Frete: 'R$ 10,00',
          Total: 'R$ 15,00',
          Reembolso: 'R$ 15,00'
        },
        {
          Actions: 5,
          SKU: 121483,
          Store: 'DrogaRaia',
          Description: 'Meia de Compressão',
          Quantity: 2,
          Price: 'R$ 2,50',
          Frete: 'R$ 10,00',
          Total: 'R$ 15,00',
          Reembolso: 'R$ 15,00'
        }
      ]
    },
    banks: [
      { value: '246', label: '246 - Banco ABC Brasil S.A.' },
      { value: '748', label: '748 - Banco Cooperativo Sicredi S.A.' },
      { value: '117', label: '117 - Advanced Cc Ltda' },
      { value: '121', label: '121 - Banco Agibank S.A.' },
      { value: '172', label: '172 - Albatross Ccv S.A' },
      { value: '188', label: '188 - Ativa Investimentos S.A' },
      {
        value: '280',
        label: '280 - Avista S.A. Crédito, Financiamento e Investimento'
      },
      { value: '080', label: '080 - B&T Cc Ltda' },
      { value: '654', label: '654 - Banco A.J.Renner S.A.' },
      { value: '075', label: '075 - Banco ABN AMRO S.A' },
      { value: '025', label: '025 - Banco Alfa S.A.' },
      { value: '641', label: '641 - Banco Alvorada S.A. ' },
      { value: '065', label: '065 - Banco Andbank (Brasil) S.A.' },
      { value: '213', label: '213 - Banco Arbi S.A.' },
      { value: '096', label: '096 - Banco B3 S.A.' },
      { value: '024', label: '024 - Banco BANDEPE S.A.' },
      { value: '318', label: '318 - Banco BMG S.A.' },
      { value: '752', label: '752 - Banco BNP Paribas Brasil S.A.' },
      { value: '107', label: '107 - Banco BOCOM BBM S.A.' },
      { value: '063', label: '063 - Banco Bradescard S.A.' },
      { value: '036', label: '036 - Banco Bradesco BBI S.A. ' },
      { value: '122', label: '122 - Banco Bradesco BERJ S.A. ' },
      { value: '204', label: '204 - Banco Bradesco Cartões S.A. ' },
      { value: '394', label: '394 - Banco Bradesco Financiamentos S.A. ' },
      { value: '237', label: '237 - Banco Bradesco S.A.' },
      { value: '218', label: '218 - Banco BS2 S.A.' },
      { value: '208', label: '208 - Banco BTG Pactual S.A.' },
      { value: '336', label: '336 - Banco C6 S.A – C6 Bank' },
      { value: '473', label: '473 - Banco Caixa Geral – Brasil S.A.' },
      { value: '412', label: '412 - Banco Capital S.A.' },
      { value: '040', label: '040 - Banco Cargill S.A.' },
      { value: '368', label: '368 - Banco Carrefour' },
      { value: '266', label: '266 - Banco Cédula S.A.' },
      { value: '739', label: '739 - Banco Cetelem S.A.' },
      { value: '233', label: '233 - Banco Cifra S.A.' },
      { value: '745', label: '745 - Banco Citibank S.A.' },
      { value: '241', label: '241 - Banco Clássico S.A.' },
      {
        value: '756',
        label: '756 - Banco Cooperativo do Brasil S.A. – BANCOOB'
      },
      { value: '222', label: '222 - Banco Credit Agricole Brasil S.A.' },
      { value: '505', label: '505 - Banco Credit Suisse (Brasil) S.A.' },
      { value: '069', label: '069 - Banco Crefisa S.A.' },
      { value: '003', label: '003 - Banco da Amazônia S.A.' },
      { value: '083', label: '083 - Banco da China Brasil S.A.' },
      { value: '707', label: '707 - Banco Daycoval S.A.' },
      {
        value: '051',
        label: '051 - Banco de Desenvolvimento do Espírito Santo S.A. '
      },
      { value: '300', label: '300 - Banco de La Nacion Argentina' },
      { value: '495', label: '495 - Banco de La Provincia de Buenos Aires' },
      {
        value: '494',
        label: '494 - Banco de La Republica Oriental del Uruguay'
      },
      { value: '335', label: '335 - Banco Digio S.A' },
      { value: '001', label: '001 - Banco do Brasil S.A.' },
      { value: '047', label: '047 - Banco do Estado de Sergipe S.A.' },
      { value: '037', label: '037 - Banco do Estado do Pará S.A.' },
      {
        value: '041',
        label: '041 - Banco do Estado do Rio Grande do Sul S.A.'
      },
      { value: '004', label: '004 - Banco do Nordeste do Brasil S.A.' },
      { value: '196', label: '196 - Banco Fair Corretora de Câmbio S.A' },
      { value: '265', label: '265 - Banco Fator S.A.' },
      { value: '224', label: '224 - Banco Fibra S.A.' },
      { value: '626', label: '626 - Banco Ficsa S.A.' },
      { value: '094', label: '094 - Banco Finaxis S.A.' },
      { value: '612', label: '612 - Banco Guanabara S.A.' },
      { value: '012', label: '012 - Banco Inbursa S.A.' },
      { value: '604', label: '604 - Banco Industrial do Brasil S.A.' },
      { value: '653', label: '653 - Banco Indusval S.A.' },
      { value: '077', label: '077 - Banco Inter S.A.' },
      { value: '249', label: '249 - Banco Investcred Unibanco S.A. ' },
      { value: '184', label: '184 - Banco Itaú BBA S.A.' },
      { value: '029', label: '029 - Banco Itaú Consignado S.A. ' },
      { value: '479', label: '479 - Banco ItauBank S.A' },
      { value: '376', label: '376 - Banco J. P. Morgan S.A.' },
      { value: '074', label: '074 - Banco J. Safra S.A.' },
      { value: '217', label: '217 - Banco John Deere S.A.' },
      { value: '076', label: '076 - Banco KDB S.A.' },
      { value: '757', label: '757 - Banco KEB HANA do Brasil S.A.' },
      { value: '600', label: '600 - Banco Luso Brasileiro S.A.' },
      { value: '243', label: '243 - Banco Máxima S.A.' },
      { value: '720', label: '720 - Banco Maxinvest S.A.' },
      { value: '389', label: '389 - Banco Mercantil do Brasil S.A.' },
      { value: '370', label: '370 - Banco Mizuho do Brasil S.A.' },
      { value: '746', label: '746 - Banco Modal S.A.' },
      { value: '066', label: '066 - Banco Morgan Stanley S.A.' },
      { value: '456', label: '456 - Banco MUFG Brasil S.A.' },
      { value: '169', label: '169 - Banco Olé Bonsucesso Consignado S.A.' },
      { value: '111', label: '111 - Banco Oliveira Trust Dtvm S.A' },
      { value: '079', label: '079 - Banco Original do Agronegócio S.A.' },
      { value: '212', label: '212 - Banco Original S.A.' },
      { value: '712', label: '712 - Banco Ourinvest S.A.' },
      { value: '623', label: '623 - Banco PAN S.A.' },
      { value: '611', label: '611 - Banco Paulista S.A.' },
      { value: '643', label: '643 - Banco Pine S.A.' },
      { value: '658', label: '658 - Banco Porto Real de Investimentos S.A. ' },
      { value: '747', label: '747 - Banco Rabobank International Brasil S.A.' },
      { value: '633', label: '633 - Banco Rendimento S.A.' },
      { value: '741', label: '741 - Banco Ribeirão Preto S.A.' },
      { value: '120', label: '120 - Banco Rodobens S.A.' },
      { value: '422', label: '422 - Banco Safra S.A.' },
      { value: '033', label: '033 - Banco Santander (Brasil) S.A.' },
      { value: '743', label: '743 - Banco Semear S.A.' },
      { value: '754', label: '754 - Banco Sistema S.A.' },
      { value: '630', label: '630 - Banco Smartbank S.A. ' },
      { value: '366', label: '366 - Banco Société Générale Brasil S.A.' },
      { value: '637', label: '637 - Banco Sofisa S.A.' },
      { value: '464', label: '464 - Banco Sumitomo Mitsui Brasileiro S.A.' },
      { value: '082', label: '082 - Banco Topázio S.A.' },
      { value: '634', label: '634 - Banco Triângulo S.A.' },
      { value: '018', label: '018 - Banco Tricury S.A.' },
      { value: '655', label: '655 - Banco Votorantim S.A.' },
      { value: '610', label: '610 - Banco VR S.A.' },
      { value: '119', label: '119 - Banco Western Union do Brasil S.A.' },
      { value: '124', label: '124 - Banco Woori Bank do Brasil S.A.' },
      { value: '348', label: '348 - Banco Xp S/A ' },
      { value: '081', label: '081 - BancoSeguro S.A.' },
      {
        value: '021',
        label: '021 - BANESTES S.A. Banco do Estado do Espírito Santo'
      },
      {
        value: '755',
        label: '755 - Bank of America Merrill Lynch Banco Múltiplo S.A.'
      },
      { value: '268', label: '268 - Barigui Companhia Hipotecária ' },
      { value: '250', label: '250 - BCV – Banco de Crédito e Varejo S.A.' },
      { value: '144', label: '144 - BEXS Banco de Câmbio S.A.' },
      { value: '253', label: '253 - Bexs Corretora de Câmbio S/A ' },
      { value: '134', label: '134 - Bgc Liquidez Dtvm Ltda' },
      { value: '017', label: '017 - BNY Mellon Banco S.A.' },
      { value: '301', label: '301 - Bpp Instituição De Pagamentos S.A' },
      { value: '126', label: '126 - BR Partners Banco de Investimento S.A.' },
      { value: '070', label: '070 - BRB – Banco de Brasília S.A.' },
      {
        value: '092',
        label: '092 - Brickell S.A. Crédito, Financiamento e Investimento'
      },
      {
        value: '173',
        label:
          '173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
      },
      { value: '142', label: '142 - Broker Brasil Cc Ltda' },
      {
        value: '292',
        label: '292 - BS2 Distribuidora de Títulos e Valores Mobiliários S.A. '
      },
      { value: '104', label: '104 - Caixa Econômica Federal' },
      {
        value: '288',
        label: '288 - Carol Distribuidora de Títulos e Valor Mobiliários Ltda'
      },
      { value: '130', label: '130 - Caruana Scfi' },
      { value: '159', label: '159 - Casa Credito S.A' },
      { value: '016', label: '016 - Ccm Desp Trâns Sc E Rs ' },
      {
        value: '114',
        label:
          '114 - Central Cooperativa De Crédito No Estado Do Espírito Santo'
      },
      {
        value: '320',
        label: '320 - China Construction Bank (Brasil) Banco Múltiplo S.A.'
      },
      { value: '477', label: '477 - Citibank N.A.' },
      { value: '180', label: '180 - Cm Capital Markets Cctvm Ltda' },
      { value: '127', label: '127 - Codepe Cvc S.A' },
      { value: '163', label: '163 - Commerzbank Brasil S.A. – Banco Múltiplo' },
      { value: '060', label: '060 - Confidence Cc S.A' },
      {
        value: '097',
        label: '097 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda.'
      },
      {
        value: '085',
        label: '085 - Cooperativa Central de Crédito Urbano - CECRED'
      },
      {
        value: '090',
        label:
          '090 - Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS'
      },
      {
        value: '087',
        label:
          '087 - Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná'
      },
      {
        value: '089',
        label: '089 - Cooperativa de Crédito Rural da Região da Mogiana'
      },
      { value: '286', label: '286 - Cooperativa de Crédito Rural De Ouro ' },
      {
        value: '279',
        label: '279 - Cooperativa de Crédito Rural de Primavera Do Leste '
      },
      {
        value: '273',
        label:
          '273 - Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel '
      },
      { value: '098', label: '098 - Credialiança Ccr' },
      { value: '010', label: '010 - Credicoamo' },
      { value: '133', label: '133 - Cresol Confederação' },
      { value: '182', label: '182 - Dacasa Financeira S/A' },
      { value: '487', label: '487 - Deutsche Bank S.A. – Banco Alemão' },
      { value: '140', label: '140 - Easynvest – Título Cv S.A' },
      { value: '149', label: '149 - Facta S.A. Cfi' },
      { value: '285', label: '285 - Frente Corretora de Câmbio Ltda.' },
      {
        value: '278',
        label:
          '278 - Genial Investimentos Corretora de Valores Mobiliários S.A.'
      },
      { value: '138', label: '138 - Get Money Cc Ltda' },
      {
        value: '064',
        label: '064 - Goldman Sachs do Brasil Banco Múltiplo S.A.'
      },
      {
        value: '177',
        label: '177 - Guide Investimentos S.A. Corretora de Valores'
      },
      { value: '146', label: '146 - Guitta Corretora de Câmbio Ltda' },
      {
        value: '078',
        label: '078 - Haitong Banco de Investimento do Brasil S.A.'
      },
      { value: '062', label: '062 - Hipercard Banco Múltiplo S.A.' },
      {
        value: '189',
        label: '189 - HS Financeira S/A Crédito, Financiamento e Investimentos'
      },
      {
        value: '269',
        label: '269 - HSBC Brasil S.A. – Banco de Investimento '
      },
      {
        value: '271',
        label:
          '271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
      },
      { value: '157', label: '157 - Icap Do Brasil Ctvm Ltda ' },
      { value: '132', label: '132 - ICBC do Brasil Banco Múltiplo S.A.' },
      { value: '492', label: '492 - ING Bank N.V.' },
      {
        value: '139',
        label: '139 - Intesa Sanpaolo Brasil S.A. – Banco Múltiplo'
      },
      { value: '652', label: '652 - Itaú Unibanco Holding S.A.' },
      { value: '341', label: '341 - Itaú Unibanco S.A.' },
      {
        value: '488',
        label: '488 - JPMorgan Chase Bank, National Association'
      },
      { value: '399', label: '399 - Kirton Bank S.A. – Banco Múltiplo ' },
      {
        value: '293',
        label:
          '293 - Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.'
      },
      {
        value: '105',
        label: '105 - Lecca Crédito, Financiamento e Investimento S/A'
      },
      { value: '145', label: '145 - Levycam Ccv Ltda' },
      { value: '113', label: '113 - Magliano S.A' },
      { value: '323', label: '323 - Mercado Pago – Conta Do Mercado Livre' },
      { value: '128', label: '128 - MS Bank S.A. Banco de Câmbio' },
      { value: '137', label: '137 - Multimoney Cc Ltda ' },
      { value: '014', label: '014 - Natixis Brasil S.A. Banco Múltiplo' },
      {
        value: '191',
        label:
          '191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.'
      },
      {
        value: '753',
        label: '753 - Novo Banco Continental S.A. – Banco Múltiplo'
      },
      { value: '260', label: '260 - Nu Pagamentos S.A (Nubank)' },
      { value: '613', label: '613 - Omni Banco S.A.' },
      { value: '290', label: '290 - Pagseguro Internet S.A' },
      { value: '254', label: '254 - Paraná Banco S.A.' },
      {
        value: '326',
        label: '326 - Parati – Crédito Financiamento e Investimento S.A.'
      },
      {
        value: '194',
        label:
          '194 - Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda'
      },
      { value: '174', label: '174 - Pernambucanas Financ S.A' },
      { value: '100', label: '100 - Planner Corretora De Valores S.A' },
      { value: '125', label: '125 - Plural S.A. – Banco Múltiplo' },
      { value: '093', label: '093 - Pólocred Scmepp Ltda' },
      { value: '108', label: '108 - Portocred S.A' },
      { value: '283', label: '283 - Rb Capital Investimentos Dtvm Ltda' },
      { value: '101', label: '101 - Renascenca Dtvm Ltda' },
      { value: '270', label: '270 - Sagitur Corretora de Câmbio Ltda.' },
      { value: '751', label: '751 - Scotiabank Brasil S.A. Banco Múltiplo' },
      {
        value: '276',
        label: '276 - Senff S.A. – Crédito, Financiamento e Investimento'
      },
      { value: '545', label: '545 - Senso Ccvm S.A' },
      { value: '190', label: '190 - Servicoop' },
      { value: '183', label: '183 - Socred S.A' },
      {
        value: '299',
        label: '299 - Sorocred Crédito, Financiamento e Investimento S.A. '
      },
      {
        value: '118',
        label: '118 - Standard Chartered Bank (Brasil) S/A–Bco Invest.'
      },
      { value: '197', label: '197 - Stone Pagamentos S.A' },
      {
        value: '340',
        label:
          '340 - Super Pagamentos e Administração de Meios Eletrônicos S.A.'
      },
      { value: '095', label: '095 - Travelex Banco de Câmbio S.A.' },
      { value: '143', label: '143 - Treviso Corretora de Câmbio S.A.' },
      { value: '131', label: '131 - Tullett Prebon Brasil Cvc Ltda' },
      { value: '129', label: '129 - UBS Brasil Banco de Investimento S.A.' },
      { value: '091', label: '091 - Unicred Central Rs' },
      { value: '136', label: '136 - Unicred Cooperativa' },
      {
        value: '099',
        label:
          '099 - UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.'
      },
      {
        value: '084',
        label:
          '084 - Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências'
      },
      { value: '298', label: '298 - Vips Cc Ltda' },
      {
        value: '310',
        label: '310 - Vortx Distribuidora de Títulos e Valores Mobiliários Ltda'
      },
      { value: '102', label: '102 - Xp Investimentos S.A' },
      { value: '007', label: '007 - Bndes' },
      { value: '011', label: '011 - C.Suisse Hedging-Griffo Cv S/A' },
      { value: '015', label: '015 - Ubs Brasil Cctvm S.A.' },
      { value: '021', label: '021 - Bco Banestes S.A.' },
      { value: '041', label: '041 - Bco Do Estado Do Rs S.A.' },
      { value: '064', label: '064 - Goldman Sachs Do Brasil Bm S.A' },
      { value: '078', label: '078 - Haitong Bi Do Brasil S.A.' },
      { value: '084', label: '084 - Uniprime Do Brasil - Coop' },
      { value: '085', label: '085 - Coopcentral Ailos' },
      { value: '088', label: '088 - Banco Randon S.A.' },
      { value: '089', label: '089 - Credisan Cc' },
      { value: '092', label: '092 - Brk S.A. Cfi' },
      {
        value: '097',
        label: '097 - Credisis Central De Cooperativas De Crédito Ltda.'
      },
      { value: '099', label: '099 - Uniprime Coopcentral Ltda.' },
      { value: '105', label: '105 - Lecca Cfi S.A.' },
      {
        value: '114',
        label:
          '114 - Central Cooperativa De Crédito No Estado Do Espírito Santo'
      },
      { value: '139', label: '139 - Intesa Sanpaolo Brasil S.A. Bm' },
      { value: '173', label: '173 - Brl Trust Dtvm Sa' },
      { value: '177', label: '177 - Guide' },
      { value: '189', label: '189 - Hs Financeira' },
      { value: '191', label: '191 - Nova Futura Ctvm Ltda.' },
      { value: '194', label: '194 - Parmetal Dtvm Ltda' },
      { value: '195', label: '195 - Valor Scd S.A.' },
      { value: '259', label: '259 - Moneycorp Bco De Câmbio S.A.' },
      { value: '269', label: '269 - Bco Hsbc S.A.' },
      { value: '271', label: '271 - Ib Cctvm S.A.' },
      { value: '272', label: '272 - Agk Cc S.A.' },
      { value: '273', label: '273 - Ccr De São Miguel Do Oeste' },
      { value: '274', label: '274 - Money Plus Scmepp Ltda' },
      { value: '276', label: '276 - Bco Senff S.A.' },
      { value: '278', label: '278 - Genial Investimentos Cvm S.A.' },
      { value: '279', label: '279 - Ccr De Primavera Do Leste' },
      { value: '280', label: '280 - Will Financeira S.A.Cfi' },
      { value: '281', label: '281 - Ccr Coopavel' },
      { value: '288', label: '288 - Carol Dtvm Ltda.' },
      { value: '289', label: '289 - Efx Cc Ltda.' },
      { value: '292', label: '292 - Bs2 Dtvm S.A.' },
      { value: '293', label: '293 - Lastro Rdv Dtvm Ltda' },
      { value: '296', label: '296 - Oz Corretora De Câmbio S.A.' },
      { value: '299', label: '299 - Bco Sorocred S.A. - Bm' },
      { value: '306', label: '306 - Portopar Dtvm Ltda' },
      { value: '307', label: '307 - Terra Investimentos Dtvm' },
      { value: '309', label: '309 - Cambionet Cc Ltda' },
      { value: '310', label: '310 - Vortx Dtvm Ltda.' },
      { value: '311', label: '311 - Dourada Corretora' },
      { value: '312', label: '312 - Hscm Scmepp Ltda.' },
      { value: '313', label: '313 - Amazônia Cc Ltda.' },
      { value: '319', label: '319 - Om Dtvm Ltda' },
      { value: '320', label: '320 - Bco Ccb Brasil S.A.' },
      { value: '321', label: '321 - Crefaz Scmepp Ltda' },
      { value: '322', label: '322 - Ccr De Abelardo Luz' },
      { value: '324', label: '324 - Cartos Scd S.A.' },
      { value: '325', label: '325 - Órama Dtvm S.A.' },
      { value: '326', label: '326 - Parati - Cfi S.A.' },
      { value: '328', label: '328 - Cecm Fabric Calçados Sapiranga' },
      { value: '329', label: '329 - Qi Scd S.A.' },
      { value: '330', label: '330 - Banco Bari S.A.' },
      { value: '331', label: '331 - Fram Capital Dtvm S.A.' },
      { value: '332', label: '332 - Acesso Solucoes Pagamento Sa' },
      { value: '340', label: '340 - Superdigital I.P. S.A.' },
      { value: '342', label: '342 - Creditas Scd' },
      { value: '343', label: '343 - Ffa Scmepp Ltda.' },
      { value: '349', label: '349 - Al5 S.A. Cfi' },
      { value: '350', label: '350 - Crehnor Laranjeiras' },
      { value: '352', label: '352 - Toro Ctvm S.A.' },
      { value: '354', label: '354 - Necton Investimentos S.A Cvm' },
      { value: '355', label: '355 - Ótimo Scd S.A.' },
      { value: '358', label: '358 - Midway S.A. - Scfi' },
      { value: '359', label: '359 - Zema Cfi S/A' },
      { value: '360', label: '360 - Trinus Capital Dtvm' },
      { value: '362', label: '362 - Cielo S.A.' },
      { value: '363', label: '363 - Singulare Ctvm S.A.' },
      { value: '364', label: '364 - Gerencianet' },
      { value: '365', label: '365 - Simpaul' },
      { value: '367', label: '367 - Vitreo Dtvm S.A.' },
      { value: '371', label: '371 - Warren Cvmc Ltda' },
      { value: '373', label: '373 - Up.P Sep S.A.' },
      { value: '374', label: '374 - Realize Cfi S.A.' },
      { value: '377', label: '377 - Bms Scd S.A.' },
      { value: '378', label: '378 - Bco Brasileiro De Crédito S.A.' },
      { value: '379', label: '379 - Cecm Cooperforte' },
      { value: '380', label: '380 - Picpay' },
      { value: '381', label: '381 - Bco Mercedes-Benz S.A.' },
      { value: '382', label: '382 - Fiducia Scmepp Ltda' },
      { value: '383', label: '383 - Ebanx Ip Ltda.' },
      { value: '384', label: '384 - Global Scm Ltda' },
      { value: '385', label: '385 - Cecm Dos Trab.Port. Da G.Vitor' },
      { value: '386', label: '386 - Nu Financeira S.A. Cfi' },
      { value: '387', label: '387 - Bco Toyota Do Brasil S.A.' },
      { value: '390', label: '390 - Bco Gm S.A.' },
      { value: '391', label: '391 - Ccr De Ibiam' },
      { value: '393', label: '393 - Bco Volkswagen S.A' },
      { value: '395', label: '395 - F D Gold Dtvm Ltda' },
      { value: '396', label: '396 - Hub Pagamentos' },
      { value: '397', label: '397 - Listo Scd S.A.' },
      { value: '398', label: '398 - Ideal Ctvm S.A.' },
      { value: '400', label: '400 - Coop Creditag' },
      { value: '401', label: '401 - Iugu Ip S.A.' },
      { value: '402', label: '402 - Cobuccio Scd S.A.' },
      { value: '403', label: '403 - Cora Scd S.A.' },
      { value: '404', label: '404 - Sumup Scd S.A.' },
      { value: '406', label: '406 - Accredito Scd S.A.' },
      { value: '407', label: '407 - Índigo Investimentos Dtvm Ltda.' },
      { value: '408', label: '408 - Bonuspago Scd S.A.' },
      { value: '410', label: '410 - Planner Scm S.A.' },
      { value: '411', label: '411 - Via Certa Financiadora S.A. - Cfi' },
      { value: '413', label: '413 - Bco Bv S.A.' },
      { value: '414', label: '414 - Work Scd S.A.' },
      { value: '416', label: '416 - Lamara Scd S.A.' },
      { value: '418', label: '418 - Zipdin Scd S.A.' },
      { value: '419', label: '419 - Numbrs Scd S.A.' },
      { value: '421', label: '421 - Cc Lar Credi' },
      { value: '423', label: '423 - Coluna S.A. Dtvm' },
      { value: '425', label: '425 - Socinal S.A. Cfi' },
      { value: '426', label: '426 - Biorc Financeira - Cfi S.A.' },
      { value: '427', label: '427 - Cred-Ufes' },
      { value: '428', label: '428 - Credsystem Scd S.A.' },
      { value: '429', label: '429 - Crediare Cfi S.A.' },
      { value: '430', label: '430 - Ccr Seara' },
      { value: '433', label: '433 - Br-Capital Dtvm S.A.' },
      { value: '435', label: '435 - Delcred Scd S.A.' },
      { value: '438', label: '438 - Trustee Dtvm Ltda.' },
      { value: '439', label: '439 - Id Ctvm' },
      { value: '440', label: '440 - Credibrf Coop' },
      { value: '442', label: '442 - Magnetis - Dtvm' },
      { value: '443', label: '443 - Credihome Scd' },
      { value: '444', label: '444 - Trinus Scd S.A.' },
      { value: '445', label: '445 - Plantae Cfi' },
      { value: '447', label: '447 - Mirae Asset Cctvm Ltda' },
      { value: '448', label: '448 - Hemera Dtvm Ltda.' },
      { value: '449', label: '449 - Dmcard Scd S.A.' },
      { value: '450', label: '450 - Fitbank Pagamentos Eletronicos S.A.' },
      { value: '451', label: '451 - J17 - Scd S/A' },
      { value: '452', label: '452 - Credifit Scd S.A.' },
      { value: '454', label: '454 - Mérito Dtvm Ltda.' },
      { value: '457', label: '457 - Uy3 Scd S/A' },
      { value: '458', label: '458 - Hedge Investments Dtvm Ltda.' },
      { value: '459', label: '459 - Ccm Serv. Públicos Sp' },
      { value: '460', label: '460 - Unavanti Scd S/A' },
      { value: '461', label: '461 - Asaas Ip S.A.' },
      { value: '462', label: '462 - Stark Scd S.A.' },
      { value: '463', label: '463 - Azumi Dtvm' },
      { value: '465', label: '465 - Capital Consig Scd S.A.' },
      { value: '467', label: '467 - Master S/A Cctvm' },
      { value: '468', label: '468 - Portoseg S.A. Cfi' },
      { value: '469', label: '469 - Liga Invest Dtvm Ltda.' },
      { value: '470', label: '470 - Cdc Sociedade De Crédito' },
      { value: '471', label: '471 - Cecm Serv Publ Pinhão' },
      { value: '478', label: '478 - Gazincred S.A. Scfi' },
      { value: '481', label: '481 - Superlógica Scd S.A.' },
      { value: '484', label: '484 - Maf Dtvm Sa' },
      { value: '488', label: '488 - Jpmorgan Chase Bank' },
      { value: '506', label: '506 - Rji' },
      { value: '508', label: '508 - Avenue Securities Dtvm Ltda.' },
      { value: '511', label: '511 - Magnum Scd' },
      { value: '512', label: '512 - Captalys Dtvm Ltda.' },
      { value: '753', label: '753 - Novo Bco Continental S.A. - Bm' },
      { value: '755', label: '755 - Bofa Merrill Lynch Bm S.A.' },
      { value: '756', label: '756 - Banco Sicoob S.A.' },
      { value: '757', label: '757 - Bco Keb Hana Do Brasil S.A.' }
    ],
    abbreviatedUf: [
      {
        value: 'AC',
        label: 'AC'
      },
      {
        value: 'AL',
        label: 'AL'
      },
      {
        value: 'AM',
        label: 'AM'
      },
      {
        value: 'AP',
        label: 'AP'
      },
      {
        value: 'BA',
        label: 'BA'
      },
      {
        value: 'CE',
        label: 'CE'
      },
      {
        value: 'DF',
        label: 'DF'
      },
      {
        value: 'ES',
        label: 'ES'
      },
      {
        value: 'GO',
        label: 'GO'
      },
      {
        value: 'MA',
        label: 'MA'
      },
      {
        value: 'MG',
        label: 'MG'
      },
      {
        value: 'MS',
        label: 'MS'
      },
      {
        value: 'MT',
        label: 'MT'
      },
      {
        value: 'PA',
        label: 'PA'
      },
      {
        value: 'PB',
        label: 'PB'
      },
      {
        value: 'PE',
        label: 'PE'
      },
      {
        value: 'PI',
        label: 'PI'
      },
      {
        value: 'PR',
        label: 'PR'
      },
      {
        value: 'RJ',
        label: 'RJ'
      },
      {
        value: 'RN',
        label: 'RN'
      },
      {
        value: 'RO',
        label: 'RO'
      },
      {
        value: 'RR',
        label: 'RR'
      },
      {
        value: 'RS',
        label: 'RS'
      },
      {
        value: 'SC',
        label: 'SC'
      },
      {
        value: 'SE',
        label: 'SE'
      },
      {
        value: 'SP',
        label: 'SP'
      },
      {
        value: 'TO',
        label: 'TO'
      }
    ],
    uf: [
      {
        value: 'AC',
        label: 'Acre'
      },
      {
        value: 'AL',
        label: 'Alagoas'
      },
      {
        value: 'AM',
        label: 'Amazonas'
      },
      {
        value: 'AP',
        label: 'Amapá'
      },
      {
        value: 'BA',
        label: 'Bahia'
      },
      {
        value: 'CE',
        label: 'Ceará'
      },
      {
        value: 'DF',
        label: 'Distrito Federal'
      },
      {
        value: 'ES',
        label: 'Espírito Santo'
      },
      {
        value: 'GO',
        label: 'Goiás'
      },
      {
        value: 'MA',
        label: 'Maranhão'
      },
      {
        value: 'MG',
        label: 'Minas Gerais'
      },
      {
        value: 'MS',
        label: 'Mato Grosso do Sul'
      },
      {
        value: 'MT',
        label: 'Mato Grosso'
      },
      {
        value: 'PA',
        label: 'Pará'
      },
      {
        value: 'PB',
        label: 'Paraíba'
      },
      {
        value: 'PE',
        label: 'Pernambuco'
      },
      {
        value: 'PI',
        label: 'Piauí'
      },
      {
        value: 'PR',
        label: 'Paraná'
      },
      {
        value: 'RJ',
        label: 'Rio de Janeiro'
      },
      {
        value: 'RN',
        label: 'Rio Grande do Norte'
      },
      {
        value: 'RO',
        label: 'Rondônia'
      },
      {
        value: 'RR',
        label: 'Roraima'
      },
      {
        value: 'RS',
        label: 'Rio Grande do Sul'
      },
      {
        value: 'SC',
        label: 'Santa Catarina'
      },
      {
        value: 'SE',
        label: 'Sergipe'
      },
      {
        value: 'SP',
        label: 'São Paulo'
      },
      {
        value: 'TO',
        label: 'Tocantins'
      }
    ],
    boolean: [
      {
        value: true,
        label: 'Sim'
      },
      {
        value: false,
        label: 'Não'
      }
    ],
    officialStoreBoolean: [
      {
        value: true,
        label: 'Sim'
      },
      {
        value: false,
        label: 'Não'
      }
    ],
    lojas: [
      {
        value: 'DROGASIL',
        label: 'Drogasil'
      },
      {
        value: 'RAIA',
        label: 'Raia'
      }
    ],
    lojasComissoes: [
      {
        value: 2,
        label: 'Drogasil'
      },
      {
        value: 1,
        label: 'Raia'
      }
    ],
    cadastralSituation: [
      {
        value: 'ATIVA',
        label: 'Ativa'
      },
      {
        value: 'SUSPENSA',
        label: 'Suspensa'
      },
      {
        value: 'INAPTA',
        label: 'Inapta'
      },
      {
        value: 'BAIXADA',
        label: 'Baixada'
      },
      {
        value: 'NULA',
        label: 'Nula'
      }
    ],
    sellers: [
      {
        value: 'Todos',
        label: 'Todos'
      },
      {
        value: 'McDonalds',
        label: 'McDonalds'
      },
      {
        value: 'Multilaser',
        label: 'Multilaser'
      },
      {
        value: '3pEng',
        label: '3pEng'
      },
      {
        value: 'CaltonTech',
        label: 'CaltonTech'
      },
      {
        value: 'Setmaker',
        label: 'Setmaker'
      }
    ],
    adjustment: [
      {
        orderId: '123456',
        orderValue: 'R$ 20,30',
        sellerId: '1234567890',
        operation: '',
        billingCycle: '',
        reason: '',
        adjustmentValue: '',
        observation: ''
      },
      {
        orderId: '123456',
        orderValue: 'R$ 20,30',
        sellerId: '1234567890',
        operation: '',
        billingCycle: '',
        reason: '',
        adjustmentValue: '',
        observation: ''
      }
    ],
    block: [
      {
        id: '2423',
        seller: 'McDonalds',
        cnpj: '148021983091',
        mes: 'Agosto',
        ciclo: '02/08',
        saldo: 'R$ 11348,30',
        operacao: true
      },
      {
        id: '2412',
        seller: 'McDonalds',
        cnpj: '148021983091',
        mes: 'Agosto',
        ciclo: '15/08',
        saldo: 'R$ 43248,30',
        operacao: true
      },
      {
        id: '2233',
        seller: 'McDonalds',
        cnpj: '148021983091',
        mes: 'Agosto',
        ciclo: '22/08',
        saldo: 'R$ 63248,30',
        operacao: true
      },
      {
        id: '1223',
        seller: 'Multilazer',
        cnpj: '232198372184',
        mes: 'Setembro',
        ciclo: '02/09',
        saldo: 'R$ 21245,30',
        operacao: false
      },
      {
        id: '4523',
        seller: 'Multilazer',
        cnpj: '232198372184',
        mes: 'Setembro',
        ciclo: '15/09',
        saldo: 'R$ 12345,30',
        operacao: false
      },
      {
        id: '2673',
        seller: 'Multilazer',
        cnpj: '232198372184',
        mes: 'Setembro',
        ciclo: '22/09',
        saldo: 'R$ 22345,30',
        operacao: false
      }
    ],
    integradoras: [{ value: 'CONECTALA', label: 'Conecta-lá' }],
    integradoraSeller: [
      { value: 'BLING', label: 'Bling' },
      { value: 'ANYMARKET', label: 'Anymarket' },
      { value: 'VTEX', label: 'Vtex' },
      { value: 'PLUGGTO', label: 'Plugg.to' },
      { value: 'TINY', label: 'Tiny' },
      { value: 'MAGAZORD', label: 'Magazord' },
      { value: 'SHOPPING_DE_PRECO', label: 'Shopping de preço' },
      { value: 'MANUAL', label: 'Manual' },
      { value: '4MIDDLEWARE', label: '4middleware' },
      { value: 'HUB2B', label: 'Hub2b' },
      { value: 'VTRINA', label: 'Vtrina' },
      { value: 'PRECODE', label: 'Precode' },
      { value: 'WAPSTORE', label: 'Wapstore' },
      { value: 'HUBSELL', label: 'Hubsell' },
      { value: 'INSERT_SOFT', label: 'InsertSoft' },
      { value: '2LIG', label: '2Lig' },
      { value: 'PROPRIA', label: 'Propria' },
      { value: 'ANYHUB', label: 'Anyhub' },
      { value: 'LOJA_INTEGRADA', label: 'Loja Integrada' },
      { value: 'IDERIS', label: 'Ideris' }
    ],
    rdLogOptions: [
      { value: false, label: 'Outras' },
      { value: true, label: 'Logística RD' }
    ],
    porte: [
      { value: 'TOP', label: 'Top' },
      { value: 'ACELERADORA', label: 'Aceleradora' }
    ],
    representativeTypes: [
      { value: 'comercial', label: 'Comercial' },
      { value: 'qualidade', label: 'Qualidade' }
    ],
    statusRegistration: [
      { value: 'EM_CADASTRO', label: 'Em Cadastro' },
      { value: 'EM_HOMOLOGACAO', label: 'Em Homologação' },
      { value: 'CADASTRO_CONCLUIDO', label: 'Cadastro Concluído' }
    ],
    roles: [
      { value: 'admin', label: 'ADMIN' },
      { value: 'seller', label: 'SELLER' },
      { value: 'backoffice', label: 'BACKOFFICE' },
      { value: 'farming', label: 'FARMING' },
      { value: 'financial', label: 'FINANCIAL' },
      { value: 'fiscal', label: 'FISCAL' },
      { value: 'hunting', label: 'HUNTING' },
      { value: 'onboarding', label: 'ONBOARDING' },
      { value: 'setup', label: 'SETUP' },
      { value: 'suporte', label: 'SUPORTE' },
      { value: 'treinamento', label: 'TREINAMENTO' },
      { value: 'incubadora', label: 'ACELERADORA' },
      { value: 'atendimento', label: 'ATENDIMENTO' },
      { value: 'qualidade', label: 'QUALIDADE' },
      { value: 'regulatorio', label: 'REGULATÓRIO' }
    ],
    setupRoles: [{ value: 'seller', label: 'SELLER' }],
    reasonsOptions: [
      {
        value: '17',
        label: 'Estorno/Cancelamento'
      },
      {
        value: '14',
        label: 'Frete'
      },
      {
        value: '1',
        label: 'Ação Judicial'
      },
      {
        value: '13',
        label: 'Erro Operacional'
      },
      {
        value: '15',
        label: 'Imposto de Renda'
      },
      {
        value: '7',
        label: 'Comissão Divergente'
      },
      {
        value: '19',
        label: 'Antecipação Externa'
      }
    ]
  }
}

export default useMock
