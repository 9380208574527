import styled, { css } from 'styled-components'

interface Props {
  disabled: boolean
  error: boolean
}

export const Container = styled.p<Props>`
  color: ${props => props.theme.colors.global.neutral.medium};
  font-size: 10px;
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letter.spacing.tiny};
  margin-left: 4px;

  ${props =>
    props.disabled &&
    css`
      opacity: ${props.theme.opacity.level.weak};
    `}

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.colors.global.negative.pure};
    `}
`
