import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

import {
  ROUTE_DELETE_CONCILIATION,
  ROUTE_LIST_CONCILIATION,
  ROUTE_REPROCESS_ORDER
} from './paths'

const DeleteConciliation = lazy(() =>
  import('pages/Conciliation/DeleteConciliation').then(module => ({
    default: module.DeleteConciliation
  }))
)

const ListConciliation = lazy(() =>
  import('pages/Conciliation/ListConciliation').then(module => ({
    default: module.ListConciliation
  }))
)

const ReprocessOrder = lazy(() =>
  import('pages/Conciliation/ReprocessOrder').then(module => ({
    default: module.ReprocessOrder
  }))
)

export const conciliationRoutes: IRouteProps[] = [
  {
    path: ROUTE_REPROCESS_ORDER,
    exact: true,
    name: 'Reprocess Order',
    component: ReprocessOrder,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.fiscal, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Conciliação'
        }
      ],
      title: 'Reprocessar Pedido'
    }
  },
  {
    path: ROUTE_LIST_CONCILIATION,
    exact: true,
    name: 'List Conciliation',
    component: ListConciliation,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.fiscal, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Conciliação'
        }
      ],
      title: 'Consultar'
    }
  },
  {
    path: ROUTE_DELETE_CONCILIATION,
    exact: true,
    name: 'Delete Conciliation',
    component: DeleteConciliation,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.fiscal, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Conciliação'
        }
      ],
      title: 'Deletar'
    }
  }
]
