import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROUTE_MAINTENANCE_ADJUST } from 'routes/Financial/paths'
import { ROLES } from 'utils/roles'

import {
  ROUTE_INCOME,
  ROUTE_INCOME_TAX,
  ROUTE_INVOICE,
  ROUTE_INVOICE_PROCESSING_REPORT,
  ROUTE_PROCESS_INVOICE,
  ROUTE_REPORT_TAX
} from './paths'

const ListInvoices = lazy(() =>
  import('pages/Invoices/List').then(module => ({
    default: module.Invoice
  }))
)

const ProcessInvoices = lazy(() =>
  import('pages/Invoices/Process').then(module => ({
    default: module.Process
  }))
)

const ProcessingReport = lazy(() =>
  import('pages/Invoices/ProcessingReport').then(module => ({
    default: module.Report
  }))
)

const MaintenanceAdjust = lazy(() =>
  import('pages/Invoices/MaintenanceAdjust').then(module => ({
    default: module.MaintenanceAdjust
  }))
)

const Income = lazy(() =>
  import('pages/Invoices/Income').then(module => ({
    default: module.Income
  }))
)

const IncomeTax = lazy(() =>
  import('pages/Invoices/IncomeTax').then(module => ({
    default: module.IncomeTax
  }))
)
const ReportTax = lazy(() =>
  import('pages/Invoices/ReportTax').then(module => ({
    default: module.ReportTax
  }))
)

export const invoicesRoutes: IRouteProps[] = [
  {
    path: ROUTE_INVOICE,
    exact: true,
    name: 'Exibir',
    component: ListInvoices,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal,
      ROLES.seller
    ],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Exibir'
    }
  },
  {
    path: `${ROUTE_INVOICE}/:cycleNum/:idSellerVtex`,
    exact: true,
    name: 'Exibir',
    component: ListInvoices,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Exibir'
    }
  },
  {
    path: ROUTE_PROCESS_INVOICE,
    exact: true,
    name: 'Processar',
    component: ProcessInvoices,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Processar'
    }
  },
  {
    path: ROUTE_INVOICE_PROCESSING_REPORT,
    exact: true,
    name: 'Relatório de Faturamento',
    component: ProcessingReport,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Relatório de Faturamento'
    }
  },
  {
    path: ROUTE_MAINTENANCE_ADJUST,
    exact: true,
    name: 'Exclusão de Faturamento',
    component: MaintenanceAdjust,
    isPrivate: true,
    roles: [ROLES.fiscal, ROLES.financial, ROLES.admin],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Exclusão de Faturamento'
    }
  },
  {
    path: ROUTE_INCOME,
    exact: true,
    name: 'Informe de Rendimento',
    component: Income,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.farming,
      ROLES.setup,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal,
      ROLES.seller
    ],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Informe de Rendimento'
    }
  },
  {
    path: ROUTE_INCOME_TAX,
    exact: true,
    name: 'Acompanhar Informe',
    component: IncomeTax,
    isPrivate: true,
    roles: [
      ROLES.admin,
      ROLES.financial,
      ROLES.setup,
      ROLES.farming,
      ROLES.hunting,
      ROLES.backoffice,
      ROLES.suporte,
      ROLES.treinamento,
      ROLES.incubadora,
      ROLES.qualidade,
      ROLES.fiscal
    ],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Acompanhar Informe'
    }
  },
  {
    path: ROUTE_REPORT_TAX,
    exact: true,
    name: 'Enviar Informe de Rendimento',
    component: ReportTax,
    isPrivate: true,
    roles: [ROLES.seller],
    breadcrumb: {
      items: [
        {
          label: 'Notas Fiscais'
        }
      ],
      title: 'Enviar Informe de Rendimento'
    }
  }
]
