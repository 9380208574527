import { lazy } from 'react'

import { IRouteProps } from 'routes/CustomRoute.interface'

import { ROUTE_CREATE_SELLER } from './paths'

const CreateSeller = lazy(() =>
  import('pages/Seller/Public/CreateSeller').then(module => ({
    default: module.CreateSeller
  }))
)

export const sellerPublicRoutes: IRouteProps[] = [
  {
    path: `${ROUTE_CREATE_SELLER}/:token`,
    exact: true,
    name: 'Create Seller',
    component: CreateSeller,
    isPrivate: false
  }
]
