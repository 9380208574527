/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
import { useCallback, useState } from 'react'

import { Formik, Form, Field, FormikProps } from 'formik'

import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai'

import { Row, Col } from 'antd'

import { useToast } from 'hooks/useToast'
import { useLoader } from 'hooks/useLoader'

import { Input } from 'components/Form/Input'
import { Button } from 'components/Button'

import { getValidationsErrors } from 'utils/yup/getValidationsErrors'

import {
  Container,
  Head,
  InputStyle,
  LoginImg,
  PasswordRequirements,
  CheckColor
} from './styles'
import { FIRST_ACCESS } from 'services/graphql/mutations/Auth/FirstAccess'
import { useMutation } from '@apollo/client'
import { IFirstAccess, initialValues, validationSchema } from './form'
import theme from 'styles/theme'
import { useAuth } from 'hooks/useAuth'

export function FirstAccess() {
  const { user, signIn } = useAuth()
  const { addToast } = useToast()
  const [validationPassword, setValidationPassword] = useState({
    moreEightChars: false,
    hasNumber: false,
    hasLetter: false,
    includesEmail: false,
    value: ''
  })

  const { showLoader, hideLoader } = useLoader()

  const [firstAccess] = useMutation(FIRST_ACCESS)

  const handleValidate = useCallback(async (formValues: IFirstAccess) => {
    const validations = await getValidationsErrors(formValues, validationSchema)

    return validations
  }, [])
  const handlePassword = useCallback(
    (value: string) => {
      const moreEightChars = value.length >= 8
      const hasNumber = !!value.match(/[0-9]|\W|_/i)?.length
      const hasLetter = !!value.match(/[a-z]|[A-Z]/i)?.length

      const includesEmail = value.match(
        new RegExp(user.email.split('@')[0].split(/\W|_/).join('|'), 'i')
      )

      setValidationPassword({
        moreEightChars,
        hasNumber,
        hasLetter,
        includesEmail: !!includesEmail,
        value: value
      })
    },
    [user]
  )
  const handleOnSubmit = useCallback(
    async (formValues: IFirstAccess) => {
      if (validationPassword.includesEmail) {
        return
      }

      showLoader()

      firstAccess({
        variables: {
          input: {
            email: user.email,
            newPassword: formValues.newPassword,
            passwordConfirmation: formValues.newPassword
          }
        }
      })
        .then(() => {
          signIn({
            username: user.email,
            password: formValues.newPassword
          }).catch(err => {
            addToast({
              title: err.message,
              description: '',
              type: 'error'
            })
          })
        })
        .catch(err => {
          addToast({
            title: err.message,
            description: '',
            type: 'error'
          })
        })
        .finally(() => {
          hideLoader()
        })
    },
    [
      showLoader,
      addToast,
      hideLoader,
      firstAccess,
      validationPassword,
      user,
      signIn
    ]
  )

  return (
    <Container>
      <Row>
        <Col span={10}>
          <LoginImg />
        </Col>

        <Col span={14}>
          <Row
            align="middle"
            justify="center"
            style={{ height: '100vh', maxWidth: '800px', margin: '0 auto' }}
          >
            <Col span={16}>
              <Row>
                <Col span={24}>
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={initialValues}
                    validate={handleValidate}
                    onSubmit={handleOnSubmit}
                  >
                    {(formik: FormikProps<IFirstAccess>) => (
                      <Form>
                        <Row gutter={[0, 16]} align="middle" justify="center">
                          <Col span={24}>
                            <Head>
                              Olá, como é seu primeiro acesso, para a sua
                              segurança pedimos que crie uma senha para acessar
                              o Portal Financeiro.
                            </Head>
                          </Col>
                          <Col span={24}>
                            <InputStyle data-testid="password">
                              <Field
                                as={Input}
                                label="Senha"
                                type="password"
                                placeholder=" "
                                name="password"
                                isFloating
                                onChange={(e: any) =>
                                  handlePassword(e.target.value)
                                }
                                messageError={formik.errors.password}
                              />
                            </InputStyle>
                          </Col>
                          <Col span={24}>
                            {validationPassword.value && (
                              <PasswordRequirements>
                                <p className="head">Crie uma senha:</p>
                                <p className="body">
                                  <span>
                                    {validationPassword.moreEightChars ? (
                                      <AiOutlineCheck
                                        color={
                                          theme.colors.global.positive.pure
                                        }
                                        size={10}
                                      />
                                    ) : (
                                      <AiOutlineClose
                                        color={
                                          theme.colors.global.negative.pure
                                        }
                                        size={10}
                                      />
                                    )}
                                  </span>
                                  <CheckColor
                                    error={!validationPassword.moreEightChars}
                                  >
                                    Com pelo menos 8 caracteres
                                  </CheckColor>
                                </p>
                                <p className="body">
                                  <span>
                                    {validationPassword.hasLetter ? (
                                      <AiOutlineCheck
                                        color={
                                          theme.colors.global.positive.pure
                                        }
                                        size={10}
                                      />
                                    ) : (
                                      <AiOutlineClose
                                        color={
                                          theme.colors.global.negative.pure
                                        }
                                        size={10}
                                      />
                                    )}
                                  </span>
                                  <CheckColor
                                    error={!validationPassword.hasLetter}
                                  >
                                    Ao menos uma letra
                                  </CheckColor>
                                </p>
                                <p className="body">
                                  <span>
                                    {validationPassword.hasNumber ? (
                                      <AiOutlineCheck
                                        color={
                                          theme.colors.global.positive.pure
                                        }
                                        size={10}
                                      />
                                    ) : (
                                      <AiOutlineClose
                                        color={
                                          theme.colors.global.negative.pure
                                        }
                                        size={10}
                                      />
                                    )}
                                  </span>
                                  <CheckColor
                                    error={!validationPassword.hasNumber}
                                  >
                                    Um número (0-9) ou um símbolo
                                  </CheckColor>
                                </p>
                                <p className="body">
                                  <span>
                                    {!validationPassword.includesEmail &&
                                    !!validationPassword.value.length ? (
                                      <AiOutlineCheck
                                        color={
                                          theme.colors.global.positive.pure
                                        }
                                        size={10}
                                      />
                                    ) : (
                                      <AiOutlineClose
                                        color={
                                          theme.colors.global.negative.pure
                                        }
                                        size={10}
                                      />
                                    )}
                                  </span>
                                  <CheckColor
                                    error={validationPassword.includesEmail}
                                  >
                                    Que não inclua o endereço de email
                                  </CheckColor>
                                </p>
                              </PasswordRequirements>
                            )}
                          </Col>
                          <Col span={24}>
                            <InputStyle data-testid="newPassword">
                              <Field
                                as={Input}
                                label="Confirmar Senha"
                                placeholder=" "
                                isFloating
                                type="password"
                                name="newPassword"
                                messageError={formik.errors.newPassword}
                              />
                            </InputStyle>
                          </Col>
                          <Col span={24}>
                            <Button
                              data-testid="submitButton"
                              custom="primary"
                              disabled={
                                formik.values.password !==
                                formik.values.newPassword
                              }
                              type="submit"
                              label="Cadastrar Senha"
                              style={{ width: '100%', height: '48px' }}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
