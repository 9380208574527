import { Suspense } from 'react'

import { v4 } from 'uuid'

import { Switch, Redirect } from 'react-router-dom'

import routes from 'routes'
import { CustomRoute } from 'routes/CustomRoute'

import { Loading } from 'components/Loading'

import { Body } from './Content.styles'

interface Props {
  isAuth: boolean
}

export function Content({ isAuth }: Props) {
  return (
    <Body isAuth={isAuth} role="body">
      <Suspense fallback={<Loading />}>
        <Switch>
          {routes.map(
            route =>
              route.component && (
                <CustomRoute
                  key={v4()}
                  path={route.path}
                  name={route.name}
                  exact={route.exact}
                  component={route.component}
                  isPrivate={route.isPrivate}
                  roles={route.roles}
                  breadcrumb={route.breadcrumb}
                />
              )
          )}

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </Body>
  )
}
