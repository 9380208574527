import styled, { css } from 'styled-components'

export const Backdrop = styled.div`
  ${props => css`
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    background-color: ${props.theme.colors.global.neutral.medium}99;
    overflow: auto;
  `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 700px;
  max-width: 1300px;
  background-color: ${props => props.theme.colors.background};
  margin: 10px;
  height: fit-content;
  border-radius: ${props => props.theme.border.radius.nano};
`

export const Header = styled.header`
  display: flex;
  border-top-left-radius: ${props => props.theme.border.radius.nano};
  border-top-right-radius: ${props => props.theme.border.radius.nano};
  padding: 20px;
  justify-content: flex-end;

  > div {
    flex: 1;
  }
`

export const CloseButton = styled.button`
  font-size: ${props => props.theme.font.size.medium};
  background-color: transparent;
  border: 0;
  margin-left: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;
`

export const Body = styled.section`
  padding: 10px 15px;
`
