import styled, { css } from 'styled-components'

interface Props {
  disabled: boolean
  required: boolean
  error: boolean
}

export const Container = styled.label<Props>`
  color: ${props => props.theme.colors.global.neutral.darkest};
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.micro};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.line.height.large};
  text-align: left;

  position: relative;
  left: 5px;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.global.neutral.medium};
    `};

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.colors.global.negative.pure};
    `};

  ${props =>
    props.required &&
    css`
      ::before {
        content: '*';
        color: ${props => props.theme.colors.global.negative.pure};
        font-weight: 700;
        left: -10px;
        position: absolute;
        z-index: 0;
      }
    `}
`
