import { useState } from 'react'

import { BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'

import { ThemeProvider } from 'styled-components'

import { client } from 'services/graphql'

import { AuthProvider } from 'hooks/useAuth'
import { LoaderProvider } from 'hooks/useLoader'
import { ModalProvider } from 'hooks/useModal'
import { ToastProvider } from 'hooks/useToast'

import { Layout } from 'containers/Layout'

import theme from 'styles/theme'
import GlobalStyle from 'styles/global'
import AntDesignReset from 'styles/antd/reset'
import SweetAlertReset from 'styles/sweetAlert/reset'

export function App() {
  const [clientGraphql, setClientGraphql] = useState({
    client: client
  })

  return (
    <ApolloProvider client={clientGraphql.client()}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <AuthProvider setClient={setClientGraphql}>
            <LoaderProvider>
              <ModalProvider>
                <ToastProvider>
                  <Layout />
                </ToastProvider>
              </ModalProvider>
            </LoaderProvider>
          </AuthProvider>
        </BrowserRouter>

        <GlobalStyle />
        <AntDesignReset />
        <SweetAlertReset />
      </ThemeProvider>
    </ApolloProvider>
  )
}
