import * as Yup from 'yup'

export interface IFirstAccess {
  password: string
  newPassword: string
}

export const initialValues: IFirstAccess = {
  password: '',
  newPassword: ''
}
export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Com pelo menos 8 caracteres')
    .matches(/[0-9]|\W|_/i, 'Um número (0-9) ou um símbolo')
    .matches(/[a-z]|[A-Z]/i, 'Ao menos uma letra')
    .required('Campo Obrigatório.'),
  newPassword: Yup.string().when('password', (_: any, field: any) =>
    field
      .required('Campo Obrigatório.')
      .oneOf([Yup.ref('password')], 'Campo diferente do campo senha')
  )
})
